import { useEffect } from 'react';

export function useOnLeaveUrl(action = _event => {}) {
    useEffect(() => {
        window.addEventListener('beforeunload', function (e) {
            action(e);
        });

        return () => {
            action();
            window.removeEventListener('beforeunload', function (e) {
                action(e);
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
