import { atom } from 'recoil';

const deleteCaseModalAtom = atom({
    default: '',
    key: 'deleteCaseModal'
});

const state = {
    atoms: {
        deleteCaseModalAtom
    }
};

export default state;
