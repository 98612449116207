import { useNavigate } from 'react-router-dom';

import ActiveIndicator from 'components/ActiveIndicator';
import AvatarWithTitle from 'components/AvatarWithTitle';
import BodyText from 'components/BodyText';
import Checkbox from 'components/Checkbox';
import DetailsButton from 'components/DetailsButton';
import PenIcon from 'components/icons/PenIcon';
import TableRow from 'components/TableRow';
import WithTooltipHtml from 'components/WithTooltipHtml';

import useTranslation from './hooks/useTranslation';
import useConfig from 'hooks/useConfig';

import styles from './styles.module.scss';
import { Badges } from 'components/badges/badges';

const PeopleTableRow = props => {
    const {
        selectedColumns = [],
        email,
        firstName,
        groups,
        id,
        isSelected,
        lastName,
        registration,
        role,
        status,
        tenant,
        toggleSelection,
        username
    } = props;

    const { EDIT } = useTranslation();

    const navigate = useNavigate();
    const { EDIT_PERSON_ROUTE } = useConfig();

    const isActive = ((registration === 'registered') && (status !== 'disabled'));

    return (
        <TableRow className={styles.peopleTableRow}>
            <Checkbox
                isChecked={isSelected}
                onChange={() => toggleSelection(id)}
            />

            {selectedColumns.includes('name') && (
                <AvatarWithTitle
                    user={{ id: id, name: `${firstName} ${lastName}` }}
                />
            )}

            {selectedColumns.includes('username') && (
                <BodyText text={username} />
            )}

            {selectedColumns.includes('email') && <BodyText text={email} />}

            {selectedColumns.includes('roles') && (
                <div>
                    {role.map(({ name, description }) => (
                        <WithTooltipHtml text={description}>
                            <span>{name}</span>
                        </WithTooltipHtml>
                    ))}
                </div>
            )}

            {selectedColumns.includes('groups') && (
                <Badges items={groups.map(group => ({ label: group }))} />
            )}

            {selectedColumns.includes('registration') && (
                <BodyText
                    text={
                        status !== 'disabled' && registration
                            ? registration.charAt(0).toUpperCase() +
                              registration.slice(1)
                            : status === 'disabled'
                            ? 'Disabled'
                            : ''
                    }
                />
            )}

            {selectedColumns.includes('status') && (
                <ActiveIndicator
                    className={styles.statusIcon}
                    isActive={isActive}
                />
            )}

            {selectedColumns.includes('tenant') && <BodyText text={tenant} />}

            <DetailsButton
                className={styles.detailsButton}
                items={[
                    {
                        icon: <PenIcon />,
                        onClick: () => {
                            const url = EDIT_PERSON_ROUTE.replace(':personId', id)
                            navigate(url);
                        },
                        text: EDIT
                    }
                ]}
            />
        </TableRow>
    );
};

export default PeopleTableRow;
