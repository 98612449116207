import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import CancelButton from 'components/CancelButton';
import PageTitle from 'components/PageTitle';
import PersonalInformationPanel from 'components/PersonalInformationPanel';
import SaveButton from 'components/SaveButton';

import useConfig from 'hooks/useConfig';
import useForm from 'hooks/useForm';
import useGetPerson from './hooks/useGetPerson';
import useData from './hooks/useData';

import translations from './constants';

import styles from './styles.module.scss';

const EditPerson = () => {
    const { EDIT_PERSON } = translations();

    const { HUMAN_RESOURCES } = useConfig();

    const [errorMessage, setErrorMessage] = useState();
    const [people, setPeople] = useState();

    const params = useParams();
    const { personId } = params;
    const navigate = useNavigate();

    const { data: userData } = useGetPerson({
        userId: personId
    });

    const [_updatedPerson, updatePerson] = useData();
    
    const form = useForm({
        emailAddress: '',
        firstName: '',
        lastName: ''
    });

    const { handleForm } = form;

    const handleCancel = useCallback(() => {
        navigate(HUMAN_RESOURCES);
    }, [HUMAN_RESOURCES, navigate]);

    const handleSave = useCallback(() => {
        setErrorMessage();
        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }
        const formObject = {};

        output.forEach((value, key) => {
            if(value === "" || value === null){
                setErrorMessage("Please fill all required fields");
                return;
            }
            return formObject[key] = value
        });

        formObject['emailAddress'] = formObject['emailAddress'].toLowerCase();
        formObject['userName'] = formObject['emailAddress'].split("@")[0];
        formObject['id'] = parseInt(personId);

        updatePerson(formObject);
        navigate(HUMAN_RESOURCES);
    }, [handleForm, navigate, updatePerson]);

    useEffect(() => {
        if (userData) {
            const formatData = {
                email: userData.tenant_user[0].email,
                firstName: userData.tenant_user[0].user_skematic.first_name,
                idTenant: userData.tenant_user[0].id,
                idUser: userData.tenant_user[0].user_skematic.id,
                lastName: userData.tenant_user[0].user_skematic.last_name
            };
            setPeople(formatData);
        }
    }, [userData]);

    return (
        <div className={styles.editPerson}>
            <PageTitle className={styles.pageTitle} text={EDIT_PERSON} />

            <CancelButton
                className={styles.cancelButton}
                onClick={handleCancel}
            />

            <SaveButton className={styles.saveButton} onClick={() => handleSave()} />

            <PersonalInformationPanel
                className={styles.personalInformationPanel}
                errorMessage={errorMessage}
                form={form}
                isEditingMode={true}
                people={people}
            />
        </div>
    );
};

export default EditPerson;
