import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import useGraphQL from 'hooks/useGraphQL';

import state from 'app/state';
import { useGetSchema } from 'hooks/useGetSchema';
import { useLockActivityFormsFromActivity } from 'hooks/useLockActivityForm';

const { reopenButton } = state;

const { reopenButtonAtom } = reopenButton.atoms;

const MUTATE_BUTTON = tenantSchema => gql`
mutation closeActivity($activityId: Int!) {
    update_${tenantSchema}_activity(where: {
        id: {
            _eq: $activityId
        }
    },
    _set: {
        closed: false,
        closed_date: null
    }) {
        returning {
            id
            closed
        }
    }
}
`;

const MUTATE_REOPEN = tenantSchema => gql`
mutation closeActivity($activityId: Int!, $userId: Int!) {
    update_${tenantSchema}_user_activity(where: {
        _and:{
            activity_id: {
                _eq: $activityId
            },
            user_id: {
                _eq: $userId
            },
        }
    },
    _set: {
        status: "inprogress",
    }) {
        returning {
            id
        }
    }
}
`;

const useData = (providedSchema) => {
    const tenantSchema = useGetSchema(providedSchema);
    const mutation = useGraphQL(MUTATE_BUTTON, providedSchema);
    const mutationReopen = useGraphQL(MUTATE_REOPEN, providedSchema);
    
    const {setUnlockActivityFormsFromActivity} = useLockActivityFormsFromActivity()

    const reopen = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const res = await mutation(variables);
            await mutationReopen(variables);
						setUnlockActivityFormsFromActivity({
								activityId: variables.activityId,
								tenantSchema
						})

            set(reopenButtonAtom, res?.data);
        };

        runMutation();
    });

    return [useRecoilValue(reopenButtonAtom), reopen];
};

export default useData;
