import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import CloseMenuListItem from 'components/CloseMenuListItem';

import useConfig from 'hooks/useConfig';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const CloseActivityMenuListItem = ({ activityId, activitySchema,onBeforeClose, onClick }) => {
    const [closedActivity, closeActivity, reset] = useData(activitySchema);
    const { useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();

    const { HOME } = useConfig();

    const navigate = useNavigate();

    const handleClose = useCallback(async() => {
        if(onBeforeClose) await onBeforeClose();
        await closeActivity({
            activityId,
            userId
        });
    }, [activityId, closeActivity, onBeforeClose, userId]);

    useEffect(() => {
        if (!closedActivity) {
            return;
        }

        if (isFunction(onClick)) {
            onClick(closedActivity);
        }

        setTimeout(() => {
            navigate(HOME);
        }, 1000);

        reset();
    }, [HOME, closedActivity, navigate, onClick, reset]);

    return <CloseMenuListItem onClick={handleClose} />;
};

export default CloseActivityMenuListItem;
