import DownloadButton from 'components/DownloadButton';
import { useExportActivities } from 'hooks/useExportActivities';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import styles from './styles.module.scss';
import { ExportModal } from 'components/ExportModal';
import { useRef, useState } from 'react';
import {
    useGetAllActivitiesForExport,
    formatSimpleReportActivities,
    groupActivitiesByStatus
} from 'queries/activities.query';

const WithDownloadButton = ({
    className = '',
    children,
    isDisabled = false,
    name,
    filters
}) => {
    const { useQuerySchemas, useSchema, useTenants, useUserId } =
        useGlobalStateHooks();

    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userId] = useUserId();
    const { exportDetailedActivities, exportActivities } =
        useExportActivities();

    const [showExportModal, setShowExportModal] = useState(false);
    const exportActionRef = useRef({ action: () => ({}), type: '' });

    const { mutateAsync: getAllActivities } = useGetAllActivitiesForExport();

    const emptyFilters = {
        activityAssignees: [],
        activityDueDateFrom: undefined,
        activityDueDateTo: undefined,
        activityName: undefined,
        activityRecurrence: [],
        activityReviewers: [],
        activityStatus: [],
        activityTypes: [],
        entityName: [],
        isAssignedToMe: false,
        isFlagged: false
    };

    const getFilteredData = async queriesFilters => {
        const results = await Promise.all([
            getAllActivities(queriesFilters.overdue),
            getAllActivities(queriesFilters.pending),
            getAllActivities(queriesFilters.upcoming),
            getAllActivities(queriesFilters.completed)
        ]);

        return results.flatMap(value => value);
    };

    const onSimpleExport = async () => {
        const action = async getAllData => {
            const activitiesData = getAllData
                ? await getAllActivities({
                      ...filters.global,
                      ...emptyFilters
                  })
                : await getFilteredData(filters);

            const formatData = formatSimpleReportActivities(activitiesData);

            const orderedStatusActivitys = groupActivitiesByStatus(formatData);

            exportActivities({
                complete: orderedStatusActivitys.completed ?? [],
                inprogress: orderedStatusActivitys.pending ?? [],
                name,
                overdue: orderedStatusActivitys.overdue ?? [],
                upcoming: orderedStatusActivitys.upcoming ?? []
            });
        };

        exportActionRef.current = {
            action,
            type: 'simple'
        };

        onShowExportModal();
    };

    const onDetailedExport = async () => {
        const action = async getAllData => {
            const activitiesData = getAllData
                ? await getAllActivities({
                      ...filters.global,
                      ...emptyFilters
                  })
                : await getFilteredData(filters);

            exportDetailedActivities({
                data: activitiesData,
                isAll: !filters?.isAssignedToMe,
                name,
                querySchemas,
                schema,
                tenants,
                userId
            });
        };

        exportActionRef.current = {
            action,
            type: 'detailed'
        };

        onShowExportModal();
    };

    const onShowExportModal = () => {
        setShowExportModal(true);
    };

    const onHideExportModal = () => {
        setShowExportModal(false);
    };

    const onExportAll = async () => {
        onHideExportModal();
        if (exportActionRef.current) {
            const { action } = exportActionRef.current;
            await action(true);
        }
    };

    const onExportFiltered = async () => {
        onHideExportModal();
        if (exportActionRef.current) {
            const { action } = exportActionRef.current;
            await action(false);
        }
    };

    return (
        <>
            {showExportModal && (
                <ExportModal
                    isOpen={showExportModal}
                    onClose={onHideExportModal}
                    onExportAll={onExportAll}
                    onExportFiltered={onExportFiltered}
                    title="Export Activities"
                />
            )}

            <div className={`${className} ${styles.withDownloadButton}`}>
                <DownloadButton
                    className={styles.downloadButton}
                    isDisabled={isDisabled}
                    onExcelExport={onSimpleExport}
                    onPdfExport={onDetailedExport}
                />

                {children}
            </div>
        </>
    );
};

export default WithDownloadButton;
