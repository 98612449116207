import Pill from 'components/Pill';
import styles from './styles.module.scss';
import WithTooltip from 'components/WithTooltip';
import { useRef } from 'react';

const badgeVariantByType = {
    default: {
        className: styles.badge,
        label: item => item?.label
    },
    group: {
        className: styles.groupBadge,
        label: item => item?.label ?? 'Group'
    },
    user: {
        className: styles.userBadge,
        label: item => item?.label ?? 'User'
    }
};

export const Badges = ({ items, maxVisible = 1 }) => {
    const visibleBadgesRef = useRef({});
    const visibleItems = items.slice(0, maxVisible);
    const extraCount = items.length - maxVisible;

    const mainVariant =
        badgeVariantByType[items?.at(0)?.type?.toLowerCase()] ||
        badgeVariantByType.default;

    function onVisibleBadgeRef(id, ref) {
        visibleBadgesRef.current[id] = ref;
    }

    const someTextOverflow = Object.values(visibleBadgesRef.current).some(
        ref => ref?.offsetWidth < ref?.scrollWidth
    );

    const showPlusBadge = extraCount > 0;
    const showTooltip = showPlusBadge || someTextOverflow;

    return (
        <WithTooltip
            className={styles.badgesContainer}
            showTooltip={showTooltip}
            text={
                <div>
                    {items.map((item, index) => {
                        const variant =
                            badgeVariantByType[item?.type?.toLowerCase()] ||
                            badgeVariantByType.default;
                        return (
                            <div key={`${item}${index}`}>
                                {variant.label(item)}
                            </div>
                        );
                    })}
                </div>
            }
        >
            {visibleItems.map((item, index) => {
                const variant =
                    badgeVariantByType[item?.type?.toLowerCase()] ||
                    badgeVariantByType.default;
                const label = variant.label(item);
                return (
                    <Pill
                        className={[styles.badge, variant.className].join(' ')}
                        key={`${label}${index}`}
                        ref={ref => onVisibleBadgeRef(index, ref)}
                        label={label}
                    />
                );
            })}

            {showPlusBadge && (
                <Pill
                    className={[styles.badge, mainVariant.className].join(' ')}
                    label={`+${extraCount}`}
                />
            )}
        </WithTooltip>
    );
};
