import { useLocalStorage } from './use-local-storage.hook';

export function useFormCache({ entityId, formId, storageId } = {}) {
    const storage = useLocalStorage();

    function getStorageKey({ entityId, formId } = {}) {
        return [storageId, entityId, formId].filter(Boolean).join(':');
    }

    function saveQuestionsToStorage(answers = {}) {
        localStorage.setItem(
            getStorageKey({ entityId, formId }),
            JSON.stringify(answers)
        );
    }

    function clearStorage() {
        localStorage.removeItem(storageId); // Old storage key
        clearStorageByKey({ entityId, formId });
    }

    function clearStorageByKey({ entityId, formId } = {}) {
        const keys = storage.getStorageKeysStartsWith(
            getStorageKey({ entityId, formId })
        );
        keys.forEach(key => localStorage.removeItem(key));
    }

    function loadQuestionsFromStorage() {
        const questions = localStorage.getItem(
            getStorageKey({ entityId, formId })
        );
        return questions ? JSON.parse(questions) : undefined;
    }

    return {
        clearStorage,
        clearStorageByKey,
        loadQuestionsFromStorage,
        saveQuestionsToStorage
    };
}
