import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';

const { updatePerson } = state;

const { updatePersonAtom } = updatePerson.atoms;

const MUTATION_UPDATE_USER = tenantSchema => gql`
    mutation updateUser(
        $userId: Int!,
        $email: String!,
        $username: String!,
        $firstName: String!,
        $lastName: String!
    ) {
        update_tenant_user(
            where: { id: { _eq: $userId } },
            _set: {
                email: $email,
                username: $username
            }
        ) {
            returning {
                id
            }
        }

        update_${tenantSchema}_user(
            where: { tenant_user_id: { _eq: $userId } },
            _set: {
                first_name: $firstName,
                last_name: $lastName
            }
        ) {
            returning {
                tenant_user_id
            }
        }
    }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATION_UPDATE_USER, providedSchema);

    const updatePerson = useRecoilCallback(({set} ) => variables => {
        const runMutation = async () => {
            await mutation({
                    email: variables.emailAddress,
                    firstName: variables.firstName,
                    lastName: variables.lastName,
                    userId: Number(variables.id),
                    username: variables.userName,
            });

            set(updatePersonAtom, {
                email: variables.email,
                firstName: variables.firstName,
                id: variables.id,
                lastName: variables.lastName,
                username: variables.username
            });
        };

        runMutation();
    });

    return [useRecoilValue(updatePersonAtom), updatePerson];
};

export default useData;
