import { gql, useQuery } from '@apollo/client';

import { normalizeMarketingFormData } from '../dataForms';

const queryMarketingForm = ({ marketingRequestId, schema }) => gql`
    query queryMarketingForm {
        ${schema}_marketing_request_form(where: {marketing_request_id: {_eq: ${marketingRequestId}}}) {
					form{
						id
            name
            form_questions {
                id
                required
                question {
                    formComponent
                    id
                    index
                    name
                    questionId
                    type
                    choices(order_by: {text: asc}) {
                    text
                    }
                }
                marketing_form_answers(where: {marketing_id: {_eq: ${marketingRequestId}}}, order_by: {id: desc}) {
                    id
                    user_id
                    question_id
                    answer
                    tenant_user{
                        user_${schema}{
                            first_name
                            last_name
                        }
                    }
                }
            }
					}
        }
    }
`;

const queryNewMarketingForm = (tenantSchema, formId) => gql`
    query queryMarketingForm {
        ${tenantSchema}_form(where: {id: {_eq: ${formId}}}) {
						id
            name
            form_questions {
                id
                required
                question {
                    formComponent
                    id
                    index
                    name
                    questionId
                    type
                    choices(order_by: {text: asc}) {
                    text
                    }
                }
            }
        }
    }
`;

export const useGetNewMarketingFormData = ({ formId, schema }) => {
    const { data } = useQuery(queryNewMarketingForm(schema, formId), {
        fetchPolicy: 'no-cache'
    });
    return normalizeMarketingFormData({
        data: data?.[`${schema}_form`],
        schema
    });
};

export const useGetMarketingFormData = ({ marketingRequestId, schema }) => {
    const queryMFD = queryMarketingForm({ marketingRequestId, schema });
    const data = useQuery(
        queryMFD,
        {
            fetchPolicy: 'no-cache'
        }
    );

    const marketingFormData = (
        data?.data?.[`${schema}_marketing_request_form`] ?? []
    ).map(({ form }) => form);

    return normalizeMarketingFormData({
        data: marketingFormData,
        schema
    });
};
