import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateStartDate } = state;

const { updateStartDateAtom } = updateStartDate.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateActivityInstructions($text: timestamp!, $activityId: Int!) {
    update_${tenantSchema}_activity(where: {id: {_eq: $activityId}}, _set: {start_date: $text}) {
      returning {
        start_date
      }
    }
  }
`;

const useData = providedSchema => {
      const { useSchema } = useGlobalStateHooks();
    const [schema] = useSchema();
    const usedSchema = providedSchema ? providedSchema : schema;
    const mutation = useGraphQL(MUTATE, usedSchema);

    const saveStartDate = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const res = await mutation(variables);

            const newStartDate =
                res?.data[`update_${usedSchema}_activity`].returning[0]
                    ?.start_date;

            set(updateStartDateAtom, newStartDate);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateStartDateAtom, '');
    });

    return [useRecoilValue(updateStartDateAtom), saveStartDate, reset];
};

export default useData;
