import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = (tenantSchema, userId) => gql`
query getUser {
    tenant_user(where: {id: {_eq: ${userId}}}) {
    id
    email
    user_${tenantSchema} {
      id
      first_name
      last_name
    }
  }
}
`;

const useGetPerson = ({userId}) => {
    const { useSchema } = useGlobalStateHooks();
    const [schema] = useSchema();
    return useQuery(query(schema, userId), {
        fetchPolicy: 'no-cache'
    });
};

export default useGetPerson;