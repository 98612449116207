import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const querySpecForm = (tenantSchema, formId, userGQL) => gql`
    query getForm {
        ${tenantSchema}_form_by_pk(id: ${formId}) {
          id
          is_active
          name
          notes
          parent_form_id
          root_form_id
          parent_form {
            name
          }
          root_form {
            name
          }
          form_questions {
            required
            question {
              formComponent
              index
              name
              questionId
              type
              id
              choices {
                id
                text
              }
              settings
            }
          }
          activity_forms {
            activity {
              id
              name
            }
            assignment
            assignment_type
          }
          tenant_user {
            ${userGQL}
            id
          }
        }
      }
`;

const useData = (formId, formSchema) => {
  const { useQuerySchemas, useSchema } = useGlobalStateHooks();

  const [querySchemas] = useQuerySchemas();
  const [schema] = useSchema();
  
  var userGQL = '';
  if(querySchemas.length > 0){
    for(const s of querySchemas){
        if(s.value){
            userGQL += `user_${s.value} {
                first_name 
                last_name 
            }`
        }
    }
  }else{
    if(schema){
        userGQL += `user_${schema} {
            first_name 
            last_name 
        }`
    }
  }

  return useQuery(querySpecForm(formSchema, formId, userGQL), {
      fetchPolicy: 'no-cache'
  });
};

export default useData;
