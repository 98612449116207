import TextInput from 'components/TextInput';
import Label from 'components/Label';
import CheckboxWithLabel from 'components/CheckboxWithLabel';
import DynamicRadioButtons from 'components/DynamicRadioButtons';
import Dropdown from 'components/Dropdown';

import styles from './styles.module.scss';
import Editor from 'components/Editor';
import FormField from 'components/FormField';
import { FormTable } from 'components/form-table/form-table';

const FormComponent = props => {
    const { handleUpdateSelections, question, questions, selections} = props;
    if(question.type === 'title'){
        return(<h2>{question.name}</h2>)
    }else if(question.type === 'text'){
        return(<p>{question.name}</p>)
    }else if(question.type === 'textInput'){
        return(<TextInput label={question.name} />)
    }else if(question.type === 'textAreaInput'){
        return(<FormField label={question.name}>
                    <Editor/>
                </FormField>)
    }else if(question.type === 'radioInput'){
        return(
            <>
            <Label text={question.name} />

            <DynamicRadioButtons
                className={styles.radioButtons} 
                items={question.choices}
                onChange={res => handleUpdateSelections(question.name, res)}
            />
            </>
        )
    }else if(question.type === 'dropDownInput'){
        return(
            <Dropdown
                items={question.choices}
                label={question.name}
                onChange={res => handleUpdateSelections(question.name, res)}
            />
        );
    }else if(question.type === 'checkBoxInput'){
        return(
            <>
                <Label text={question.name} />

                {question.choices.map((option, index) => {
                    return(
                        <CheckboxWithLabel
                            onChange={res => handleUpdateSelections(`${question.name}_${index}`, `${option.label}_${res}`)}
                            text={option.label}
                        />
                    );
                })}
            </>
        )
    }else if (question.type === 'conditional') {
        // eslint-disable-next-line
        const subQuestions = questions.map(
            ({ question: subQuestion /*required*/ }) => {
                if (subQuestion?.formComponent.includes(question?.questionId)) {
                    const selectionKeys = Object.keys(selections).filter(x =>
                        x.includes(question.name)
                    );
                    const selected = Object.values(
                        Object.fromEntries(
                            Object.entries(selections).filter(([key]) =>
                                selectionKeys.includes(key)
                            )
                        )
                    );
                    const dynamics = [];
                    for (const selectedAnswer of selected) {
                        const selectedAnswerArray =
                            selectedAnswer && selectedAnswer.includes('_')
                                ? selectedAnswer.split('_')
                                : selectedAnswer;
                        const statementOfTruth = Array.isArray(
                            selectedAnswerArray
                        )
                            ? selectedAnswerArray[1] === 'true' &&
                              subQuestion?.formComponent.includes(
                                  selectedAnswerArray[0]
                              )
                            : subQuestion?.formComponent.includes(
                                  selectedAnswerArray
                              );

                        if (statementOfTruth) {
                            dynamics.push(
                                <FormComponent
                                    handleUpdateSelections={
                                        handleUpdateSelections
                                    }
                                    question={subQuestion}
                                    questions={questions}
                                    selections={selections}
                                />
                            );
                        }
                    }
                    if (dynamics.length > 0) {
                        return dynamics;
                    } else {
                        return <></>;
                    }
                }
            }
        );
        return subQuestions;
    } else if (question.type === 'table') {
        return (
            <FormTable
                columns={question?.settings?.columns}
                data={question?.settings?.data}
            />
        );
    } else {
        return 'There was an error';
    }
}

export default FormComponent;
