import { gql, useMutation } from '@apollo/client';
import { useRecoilValue } from 'recoil';

import state from 'app/state';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { activityDetailsPanelAtom } = state.activityDetailsPanel.atoms;

const DELETE_COMMENT = tenantSchema => gql`
    mutation delete_comment($commentId: Int!, $activityId: Int!, $userId: Int!, $activityHistoryColor: String = "green", $activityHistoryMessage: String = "deleted a comment") {
        update_${tenantSchema}_comments(
            where: { id: { _eq: $commentId } },
            _set: { deleted: true }
        ) {
            returning {
                id
            }
        }

				insert_${tenantSchema}_activity_history_one(
            object: {
                activity_id:$activityId
								color: $activityHistoryColor,
                message: $activityHistoryMessage,
                user_id:$userId
            }
        ) {
           id
        }
    }
`;

const MUTATE_INSERT_USER_COMMENT = tenantSchema => gql`
    mutation insert_user_comment($activityComment: String!, $activityId: Int!, $userId: Int!, $activityHistoryColor: String = "green", $activityHistoryMessage: String = "added a comment") {
        insert_${tenantSchema}_comments_one(
					object: {
						activity_id:$activityId
						text:$activityComment
						user_comments: {
								data:{user_id:$userId}
							}
							}
        ) {
					id
        }

				insert_${tenantSchema}_activity_history_one(
            object: {
                activity_id:$activityId
								color: $activityHistoryColor,
                message: $activityHistoryMessage,
                user_id:$userId
            }
        ) {
           id
        }
    }
`;

const UPDATE_COMMENT = tenantSchema => gql`
    mutation update_comment($commentId: Int!, $newText: String!,$activityId: Int!, $userId: Int!, $activityHistoryColor: String = "green", $activityHistoryMessage: String = "edited a comment") {
        update_${tenantSchema}_comments(
            where: { id: { _eq: $commentId } },
            _set: { text: $newText }
        ) {
            returning {
                id
            }
        }

				insert_${tenantSchema}_activity_history_one(
            object: {
                activity_id:$activityId
								color: $activityHistoryColor,
                message: $activityHistoryMessage,
                user_id:$userId
            }
        ) {
           id
        }
    }
`;

export const useComment = (activityId, providedSchema, refetchActivity) => {
    const { useSchema, useUserId } = useGlobalStateHooks();

    const [userIdValue] = useUserId();
    const [schema] = useSchema();
    const usedSchema = providedSchema ? providedSchema : schema;

    const [deleteComment] = useMutation(DELETE_COMMENT(usedSchema));
    const [insertUserComment] = useMutation(
        MUTATE_INSERT_USER_COMMENT(usedSchema)
    );
    const [updateComment] = useMutation(UPDATE_COMMENT(usedSchema));

    const userId = Number(userIdValue);

    const insertCommentText = async (activityId, activityComment) => {
        const outputComment = await insertUserComment({
            variables: {
                activityComment,
                activityId,
                userId
            }
        });

        const { id: commentId } =
            outputComment?.data[`insert_${usedSchema}_comments_one`];

        await refetchActivity();

        return commentId;
    };

    const deleteCommentText = async commentId => {
        await deleteComment({
            variables: {
                activityId,
                commentId,
                userId
            }
        });

        await refetchActivity();
    };

    const updateCommentText = async (commentId, newText) => {
        await updateComment({
            variables: {
                activityId,
                commentId,
                newText,
                userId
            }
        });

        await refetchActivity();
    };

    return [
        useRecoilValue(activityDetailsPanelAtom),
        insertCommentText,
        deleteCommentText,
        updateCommentText
    ];
};
