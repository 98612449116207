import AvatarWithTextLink from 'components/AvatarWithTextLink';
import BodyLink from 'components/BodyLink';
import ImportantLink from 'components/ImportantLink';
import useConfig from 'hooks/useConfig';

import BarListItem from 'components/BarListItem';
import WithTooltipAvatar from 'components/WithTooltipAvatar';
import WithTooltip from 'components/WithTooltip';
import DocumentIcon from 'components/icons/DocumentIcon';
import { toDateString } from 'utilities/common.util';
import { safeGetLocalStorage } from 'utilities/localStorage';
import titleCase from 'utilities/titleCase';
import styles from './styles.module.scss';
import { ActivityTableActions } from './activity-table-actions';

const activityStatusStyle = {
    completed: styles.listItemCompleted,
    overdue: styles.listItemOverdue,
    pending: styles.listItemPending,
    upcoming: styles.listItemUpcoming
};

export const ActivityTableItem = ({
    activity,
    className = '',
    isMultiTenant,
    onComplete,
    onToggleFlag
}) => {
    const { ACTIVITY_ROUTE } = useConfig();

    const {
        activity_type,
        assigned,
        due_date,
        entities = [],
        id,
        name,
        recurrence,
        reviewer,
        schema,
        start_date,
        status,
        policyReference
    } = activity;

    const newTab = safeGetLocalStorage('openActivitiesAndCasesInNewTab', false);

    const activityRoute = ACTIVITY_ROUTE.replace(':activityId', id).replace(
        ':activitySchema',
        schema
    );

    return (
        <BarListItem
            className={[className, activityStatusStyle[status]]
                .filter(Boolean)
                .join(' ')}
        >
            <ImportantLink
                className={styles.text}
                text={name}
                to={activityRoute}
            />

            <BodyLink
                className={styles.date}
                newTab={newTab}
                text={toDateString(start_date)}
                to={activityRoute}
            />

            <BodyLink
                className={styles.date}
                newTab={newTab}
                text={toDateString(due_date)}
                to={activityRoute}
            />

            <BodyLink
                className={styles.avatar}
                newTab={newTab}
                text={activity_type.name}
                to={activityRoute}
            />

            <WithTooltipAvatar assigned={assigned} section={'overdue'}>
                <AvatarWithTextLink
                    assigned={assigned}
                    className={styles.link}
                    newTab={newTab}
                    section={'overdue'}
                    to={activityRoute}
                />
            </WithTooltipAvatar>

            <WithTooltipAvatar assigned={reviewer} section={'overdue'}>
                <AvatarWithTextLink
                    assigned={reviewer}
                    className={styles.link}
                    newTab={newTab}
                    section={'overdue'}
                    to={activityRoute}
                />
            </WithTooltipAvatar>

            {isMultiTenant && (
                <BodyLink
                    className={styles.assigned}
                    newTab={newTab}
                    text={schema ? titleCase(schema) : '-'}
                    to={activityRoute}
                />
            )}

            {policyReference && policyReference.length > 0 ? (
                <WithTooltip text={policyReference?.length > 0 ? policyReference[0]?.name : '-'}>
                    <DocumentIcon className={styles.icon} />
                </WithTooltip>
            ):(
                <BodyLink
                        className={styles.assigned}
                        newTab={newTab}
                        text={'-'}
                        to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                    />
            )}

            <BodyLink
                className={styles.assigned}
                newTab={newTab}
                text={recurrence ? titleCase(recurrence) : '-'}
                to={activityRoute}
            />

            <BodyLink
                className={styles.assigned}
                newTab={newTab}
                text={entities?.length > 0 ? entities.join(', ') : '-'}
                to={activityRoute}
            />

            <ActivityTableActions
                activity={activity}
                onComplete={onComplete}
                onToggleFlag={onToggleFlag}
                schema={schema}
            />
        </BarListItem>
    );
};
