import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('personalInformationPanel');

    return {
        EMAIL_ADDRESS: t('emailAddress'),
        FIRST_NAME: t('firstName'),
        GROUPS: t('groups'),
        LAST_NAME: t('lastName'),
        ROLE: t('role'),
        USERNAME: t('username')
    };
};

export default useTranslation;
