import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';

import CloseAndAddLogEntryMenuListItem from 'components/CloseAndAddLogEntryMenuListItem';

const CloseActivityAndAddLogEntryMenuListItem = ({
    activityId,
    activityRecord,
    activitySchema,
    groups,
    onBeforeClose,
    onClick,
    onSave,
    referencedUsers
}) => {
    const { useActivities, useUserId } = useGlobalStateHooks();
    const [_activities, setActivities] = useActivities();

    const [userId] = useUserId();

    const [closedActivity, closeActivity, reset] = useData(activitySchema);

    const { COMPLIANCE_LOG_ENTRY_ROUTE, HOME } = useConfig();

    const navigate = useNavigate();

    const [_errorMessage, setErrorMessage] = useState();

    const handleActivityClose = useCallback(
        async ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    activityId,
                    createdBy: Number(userId),
                    toClose: true,
                }
            );

            if(onBeforeClose) await onBeforeClose();

            let userToClose;
            let closedAssignmentType;
            loop1:
            for (let assignmentType in referencedUsers.current){
                const {users: assignmentUsers, status} = referencedUsers.current[assignmentType];
                if (status === 'inprogress'){
                    for (let user of assignmentUsers){
                        if (user.lastName === 'Group' && groups.current.includes(user.id)){
                            userToClose = user;
                            closedAssignmentType = assignmentType;
                            break loop1;
                        }else if (user.id === Number(userId)){
                            userToClose = user;
                            closedAssignmentType = assignmentType;
                            break loop1;
                        }
                    }
                }
            }
    
            const dataToSubmit = {
                activityId,
                createdBy: Number(userId),
                dateOfOccurrence: new Date(formObject.dateOfOccurrence),
                description: formObject['description'],
                riskLevel: formObject['riskLevel'],
                status: 'complete',
                typeId: formObject['logCategory']
            }
            if (userToClose.lastName === 'Group'){
                dataToSubmit.groupId = userToClose.id;
            }else{
                dataToSubmit.userId = userToClose.id;
            }
            dataToSubmit.assignmentType = closedAssignmentType;
            dataToSubmit.userName = `${userToClose.firstName} ${userToClose.lastName}`;
            closeActivity(dataToSubmit);
        }, [activityId, closeActivity, groups, onBeforeClose, referencedUsers, userId]
    );

    const activityName = activityRecord?.name || '-';
    const activityType = activityRecord?.type || '-';

    useEffect(() => {
        if (!closedActivity) {
            return;
        }

        setActivities({});

        if (isFunction(onSave)) {
            onSave();
        }

        if (isFunction(onClick)) {
            onClick(closedActivity);
        }

        navigate(COMPLIANCE_LOG_ENTRY_ROUTE.replace(':entryId', closedActivity))

        reset();
    }, [COMPLIANCE_LOG_ENTRY_ROUTE, HOME, closedActivity, navigate, onClick, onSave, reset, setActivities]);

    return (
        <CloseAndAddLogEntryMenuListItem
            activityName={activityName}
            activityType={activityType}
            onSave={handleActivityClose}
        />
    );
};

export default CloseActivityAndAddLogEntryMenuListItem;
