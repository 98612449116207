import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import styles from './styles.module.scss';

const Input = forwardRef((props, ref) => {
    const {
        className = '',
        id = '',
        isDisabled = false,
        isReadOnly = false,
        isRequired = false,
        hasError = false,
        name = '',
        onBlur,
        onChange,
        onClick,
        onKeyUp,
        onSubmit,
        placeholder = '',
        type = 'text',
        onRef,
        value: propValue
    } = props;

    const errorMessage = useRef('');
    const [error, setError] = useState();
    const [inputValue, setInputValue] = useState(propValue || '');

    useEffect(() => {
        if (onRef) {
            onRef({
                clear
            });
        }
    }, [onRef]);

    useEffect(() => {
        setInputValue(propValue || '');
    }, [propValue]);

    const onError = useCallback(message => {
        setError(message);

        errorMessage.current = message;
    }, []);

    const handleChange = useCallback(
        e => {
            const newValue = e.target.value;
            setInputValue(newValue);

            onChange?.(newValue);

            if (isRequired && !newValue.length) {
                onError('This field is required');
            } else {
                setError(undefined);
                errorMessage.current = '';
            }
        },
        [isRequired, onChange, onError]
    );

    useEffect(() => {
        setInputValue(propValue || '');
    }, [propValue]);

    useEffect(() => {
        const currentReference = ref?.current;

        const handleSubmit = () => {
            const newValue = currentReference?.value;
            handleChange({ target: { value: newValue } });
            onSubmit?.(newValue, errorMessage.current);
        };

        currentReference?.addEventListener('submit', handleSubmit);

        return () => {
            currentReference?.removeEventListener('submit', handleSubmit);
        };
    }, [errorMessage, handleChange, onSubmit, ref]);

    function clear() {
        setInputValue('');
    }

    const errorClassName = hasError || error ? styles.hasError : '';

    return (
        <input
            className={`${className} ${errorClassName} ${styles.input}`}
            value={inputValue}
            disabled={isDisabled}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={handleChange}
            onClick={onClick}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            readOnly={isReadOnly}
            ref={ref}
            type={type}
        />
    );
});

export default Input;
