export const getUserFromSchemaQueryKey = schema => `${schema}_user`;
export const getGroupFromSchemaQueryKey = schema => `${schema}_group`;

const getCommentUserTaggingBySchemaGql = ({ schema }) => {
    return {
        [getUserFromSchemaQueryKey(schema)]: {
            __args: {
                where: {
                    _and: [
                        {
                            _or: {
                                employee_status: { _neq: 'disabled' },
                                registration_status: { _neq: 'disabled' }
                            }
                        }
                    ]
                }
            },
            first_name: true,
            last_name: true,
            tenant_user: {
                email: true,
                id: true,
                user_roles: {
                    role: {
                        name: true
                    }
                }
            }
        },
        [getGroupFromSchemaQueryKey(schema)]: {
            description: true,
            id: true,
            name: true
        }
    };
};

export const getCommentUserTaggingGql = ({ schemas = [] }) => {
    const querySchemas = schemas.reduce((res, schema) => {
        return {
            ...res,
            ...getCommentUserTaggingBySchemaGql({
                schema
            })
        };
    }, {});

    return {
        query: {
            __name: 'getCommentUserTagging',
            ...querySchemas
        }
    };
};
