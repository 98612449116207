const translations = {
    emailAddress: 'Email Address',
    firstName: 'First Name',
    groups: 'Groups',
    lastName: 'Last Name',
    role: 'Role',
    username: 'Username'
};

export default translations;
