import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ActivitiesPanel from 'components/ActivitiesPanel';
import CasePanel from 'components/CasePanel';
import GlancePanel from 'components/GlancePanel';
import FormsPanel from 'components/FormsPanel';
import MarketingPanel from 'components/MarketingPanel';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import styles from './styles.module.scss';

import {usePermissions} from 'hooks/usePermissions';
import useData from './hooks/useData';
import normalizeData from './data';

const SHOW_FORMS_PANEL_PERMISSION = 'forms:read';
const SHOW_MARKETING_PANEL_PERMISSION = 'marketing:read';
const SHOW_CASE_PANEL_PERMISSION = 'cases:read';

const Home = () => {
    const [displayMarketing, setDisplayMarketing] = useState();
    const [formsStyle, setFormsStyle] = useState();

    const { INTERNAL_SERVER_ERROR } = useConfig();
    const {hasPermissions} = usePermissions();

    const hasPermissionToShowMarketingPanel = hasPermissions([SHOW_MARKETING_PANEL_PERMISSION]);
    const hasPermissionToShowFormsPanel = hasPermissions([SHOW_FORMS_PANEL_PERMISSION]);
    const hasPermissionToShowCasePanel = hasPermissions([SHOW_CASE_PANEL_PERMISSION]);


    const navigate = useNavigate();

    const { useSchema } = useGlobalStateHooks();
    const [schema] = useSchema();

    const { _loading, error, data } = useData();

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({ data, schema });
            if (normalizedData.length > 0) {
                const setting = normalizedData[0]?.setting;
                setDisplayMarketing(setting);
                if(displayMarketing){
                    setFormsStyle(styles.formsPanel)
                }else{
                    setFormsStyle(styles.formsPanelNoMarketing)
                }
            }else{
                setFormsStyle(styles.formsPanel)
                setDisplayMarketing(true);
            }
        }
    }, [data, displayMarketing, schema, setDisplayMarketing, setFormsStyle]);

    

    if (error) {
        navigate(INTERNAL_SERVER_ERROR);
        return;
    }

    return(
        <div className={styles.home}>
            <ActivitiesPanel className={(hasPermissionToShowFormsPanel && hasPermissionToShowMarketingPanel) ? styles.activitiesPanel : styles.activitiesPanelFullWidth} />
        
            {hasPermissionToShowFormsPanel && <FormsPanel className={formsStyle} />}

            {hasPermissionToShowMarketingPanel && <MarketingPanel className={styles.marketingPanel} />}

            <GlancePanel className={hasPermissionToShowCasePanel ? styles.glancePanel : styles.glancePanelFullWidth} />

            {hasPermissionToShowCasePanel && <CasePanel className={styles.casePanel} />}
        </div>
    );
};

export default Home;
