import { useRef } from 'react';

export function useFormsRef() {
    const formsRef = useRef({});

    function addFormRef(formId, formRef = { submit: async () => {} }) {
        formsRef.current = formsRef.current || {};
        formsRef.current[formId] = formRef;
    }

    function getFormRef(formId) {
        return formsRef.current[formId];
    }

    function getFormRefs() {
        return formsRef.current;
    }

    async function submitAllForms() {
        const forms = Object.values(formsRef.current);
        return Promise.all(forms.map(form => form.submit()));
    }

    return { addFormRef, getFormRef, getFormRefs, submitAllForms };
}
