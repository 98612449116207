import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateInstructions } = state;

const { updateInstructionsAtom } = updateInstructions.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateFormNotes($text: String!, $formId: Int!) {
    update_${tenantSchema}_form(where: {id: {_eq: $formId}}, _set: {notes: $text}) {
      returning {
        notes
      }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveNotes = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
          if(variables?.text !== ''){
            const res = await mutation(variables);

            const newNotes = res?.data[`update_${usedSchema}_form`].returning[0]?.notes;

            set(updateInstructionsAtom, newNotes);
          }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateInstructionsAtom, '');
    });

    return [useRecoilValue(updateInstructionsAtom), saveNotes, reset];
};

export default useData;
