import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function useTenantContext() {
    const { useQuerySchemas, useTenants } = useGlobalStateHooks();
    const [selectedTenantNames = []] = useTenants();
    const [tenants = []] = useQuerySchemas();

    return {
        isMultiTenant: selectedTenantNames.length > 1,
        selectedTenantNames,
        tenants
    };
}

export function useOnMultiTenantRedirect(redirectTo = '') {
    const navigate = useNavigate();
    const tenantContext = useTenantContext();
    const { isMultiTenant } = tenantContext;

    useEffect(() => {
        if (isMultiTenant && redirectTo) {
            navigate(redirectTo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMultiTenant, redirectTo]);
}
