import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';

const { addFormAnswer } = state;

const { addFormAnswerAtom } = addFormAnswer.atoms;

const MUTATE_CASE = tenantSchema => gql`
mutation insertCaseFormAnswer($answer: String!, $caseId: Int!, $formId: Int!, $questionId: Int!, $userId: Int!) {
    insert_${tenantSchema}_case_form_answers(objects: {
        case_id: $caseId,
        form_id: $formId,
        user_id: $userId,
        question_id: $questionId,
        answer: $answer
    }) {
      returning {
        id
      }
    }
  }
`;

const useData = () => {
    const mutation = useGraphQL(MUTATE_CASE);

    const createFormAnswer = useRecoilCallback(({ set }) => async formAnswer => {
        const runMutation = async (formAnswer) => {
            for (const answer of formAnswer?.answers){
              await mutation({
                answer: answer?.answer,
                caseId: formAnswer?.caseId,
                formId: formAnswer?.formId,
                questionId: answer?.questionId,
                userId: formAnswer?.userId,
              });
            }

            set(addFormAnswerAtom, 'complete');
        };

        await runMutation(formAnswer);
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(addFormAnswerAtom, '');
    });

    return [useRecoilValue(addFormAnswerAtom), createFormAnswer, reset];
};

export default useData;
