import { useState, useEffect } from 'react';
import useConfig from 'hooks/useConfig';
import StorageLink from 'components/StorageLink';
import getFileSize from 'utilities/getFileSize';
// import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import FileIcon from 'components/icons/FileIcon';

import styles from './styles.module.scss';
// import BodyText from 'components/BodyText';
import PenIcon from 'components/icons/PenIcon';
import CloseIcon from 'components/icons/CloseIcon';
import CheckIcon from 'components/icons/CheckIcon';
import Input from 'components/Input';
import handleSubmitOnEnter from 'utilities/handleSubmitOnEnter';
import DeleteAttachmentModal from 'components/DeleteAttachmentModal';
import formatDate from 'utilities/formatDate';

const AttachmentListItem = props => {
    const {
        className = '',
        closed,
        deleted,
        hasEdit = true,
        hasEditPermission = false,
        id,
        name,
        onDelete,
        onUpdate,
        providedSchema,
        size,
        uploaded_timestamp,
        url
    } = props;
    const { API_KEY, GET_FILESIZE_URL } = useConfig();
    // const { useSchema } = useGlobalStateHooks();
    // const [schema] = useSchema();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editedFilename, setEditedFilename] = useState(name);
    const sizePlaceholder = 'Size Loading...';
    const [sizeState, setSizeState] = useState(size ? size : sizePlaceholder);

    useEffect(() => {
        async function returnFileSize() {
            var fileSize = await getFileSize(providedSchema, url, API_KEY, GET_FILESIZE_URL);
            while(fileSize === 'File Size Loading...'){
                fileSize = await getFileSize(providedSchema, url, API_KEY, GET_FILESIZE_URL);
                setSizeState(fileSize);
            }
            setSizeState(fileSize);
        }
        if (!sizeState || sizeState === sizePlaceholder) {
            returnFileSize();
        }
        //eslint-disable-next-line
    }, [sizeState, providedSchema, url]);

    useEffect(() => {
        setEditedFilename(name);
    }, [editMode, name]);

    // TODO - if we can figure out who uploaded the attachment, enforce that
    //   only the uploader can change the name or delete it
    //
    const canInteractWithAttachmentOptions =
        hasEdit &&
        hasEditPermission &&
        !deleted &&
        !editMode &&
        Boolean(onDelete) &&
        Boolean(onUpdate) &&
        !closed;

    useEffect(() => {
    }, [sizeState]);

    const isStorageUrl = sizeState && sizeState !== 'File Size Loading...';

    let formattedUrl = isStorageUrl ? url : '#';
    if (!isStorageUrl) {
        try {
            formattedUrl = new URL(url).href;
        } catch (error) {
            try {
                formattedUrl = new URL(`https://${url}`).href;
            } catch (error) {
            }
        }
    }

    const FileLink = isStorageUrl ? (
            <StorageLink className={styles.fileName} providedSchema={providedSchema} text={name} url={url} />
        ) : (
            <a
                className={styles.fileName}
                href={formattedUrl}
                rel="noreferrer"
                target="_blank"
            >
                {name}
            </a>
        );

    let formattedDate = undefined;
    if (uploaded_timestamp) {
        try {
            // Hardcoded timestamp for when Adrian added a column with a default
            //   date of 2024-03-11 to the app, so we only want to show the date
            //   if it's after that date
            //
            if (uploaded_timestamp >= '2024-04-15') {
                formattedDate = formatDate(new Date(uploaded_timestamp));
            }
        } catch (error) {}
    }

    const handleOnDelete = async () => {
        await onDelete({
            attachmentId: id,
            filename: name
        });
        setDeleteModalOpen(false);
    };

    const onSubmitEditFilename = async () => {
        if (editedFilename.trim() === name.trim()) {
            setEditMode(false);
            return;
        }

        if (editedFilename.trim().length === 0) {
            return;
        }

        await onUpdate({
            attachmentId: id,
            filename: name,
            newName: editedFilename
        });

        setEditMode(false);
    };

    return (
        <div className={`${className} ${styles.attachmentListItem}`}>
            <FileIcon className={styles.fileIcon} />
            
            {!editMode && FileLink}

            {editMode && (
                <Input
                    className={`${styles.fileName} ${styles.input}`}
                    onChange={value => setEditedFilename(value)}
                    onKeyUp={handleSubmitOnEnter(onSubmitEditFilename)}
                    value={editedFilename}
                />
            )}

            <div className={styles.metadata}>
                {sizeState}

                {sizeState && formattedDate ? ' • ' : ''}

                {formattedDate}
            </div>

            {canInteractWithAttachmentOptions && (
                <div className={styles.iconsContainer}>
                    <div onClick={() => setEditMode(true)}>
                        <PenIcon className={styles.editIcon} />
                    </div>

                    <div onClick={() => setDeleteModalOpen(true)}>
                        <CloseIcon className={styles.deleteIcon} />
                    </div>
                </div>
            )}

            {editMode && (
                <div className={styles.iconsEditContainer}>
                    <div onClick={() => setEditMode(false)}>
                        <CloseIcon className={styles.deleteIcon} />
                    </div>

                    <div onClick={onSubmitEditFilename}>
                        <CheckIcon className={styles.editIcon} />
                    </div>
                </div>
            )}

            {deleteModalOpen && (
                <DeleteAttachmentModal
                    attachmentName={name}
                    onClose={() => setDeleteModalOpen(false)}
                    onSubmit={handleOnDelete}
                />
            )}
        </div>
    );
};

export default AttachmentListItem;
