import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('caseTable');

    return {
        ACTIONS: t('actions'),
        ASSIGNED_TO: t('assignedTo'),
        CASE_NAME: t('caseName'),
        CASE_TYPE: t('caseType'),
        CLOSED: t('closed'),
        COMPANY: t('company'),
        DATE_CLOSED: t('dateClosed'),
        DAYS_OUTSTANDING: t('daysOutstanding'),
        DELETE_TEXT: t('deleteText'),
        DELETE_TITLE: t('deleteTitle'),
        DUE_DATE: t('dueDate'),
        ENTITIES: t('entities'),
        ORIGINATED: t('originated'),
        OVERDUE: t('overdue'),
        PENDING: t('pending'),
        RISK_LABEL: t('riskLabel'),
        USERS_INVOLVED: t('usersInvolved')
    };
};

export default useTranslation;
