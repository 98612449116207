import { useQuery } from '@tanstack/react-query';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import { buildGqlQuery, gqlClient } from 'utilities/gql/gql.util';
import { queryKeys } from '../keys';
import {
    getCommentUserTaggingGql,
    getUserFromSchemaQueryKey,
    getGroupFromSchemaQueryKey
} from './users.gql';

export const tranformData =
    ({ consultancyUsers = [], excludeCertificationRole = false, usedSchema }) =>
    data => {
        consultancyUsers = consultancyUsers.filter(
            consultancyUser =>
                (consultancyUser.tenants ?? []).some(
                    tenant => tenant === usedSchema
                ) && (consultancyUser.tenants ?? []).length > 1
        );

        if (!data) return [];
        const itemsWithSchema = [usedSchema].reduce((acc, schema) => {
            const users = data[getUserFromSchemaQueryKey(schema)] ?? [];
            const groups = data[getGroupFromSchemaQueryKey(schema)] ?? [];

            return [
                ...acc,
                ...users
                    .filter(user => {
                        return excludeCertificationRole
                            ? !user.tenant_user.user_roles.some(
                                  userRole =>
                                      userRole.role.name === 'certification'
                              )
                            : true;
                    })
                    .map(user => {
                        const fullName = `${user['first_name']} ${user['last_name']}`;
                        return {
                            email: user['tenant_user']['email'],
                            firstName: user['first_name'],
                            id: user['tenant_user']['id'],
                            label: fullName,
                            lastName: user['last_name'],
                            query: fullName,
                            roles: user['tenant_user']['user_roles'].map(
                                role => role['role']['name']
                            ),
                            schema,
                            type: 'user'
                        };
                    }),
                ...groups
                    .filter(
                        group =>
                            group['name'] !== 'All Employees' &&
                            group['description'] !== 'All Employees'
                    )
                    .map(group => {
                        return {
                            description: group['description'],
                            id: group['id'],
                            label: group['name'],
                            name: group['name'],
                            query: group['name'],
                            schema: usedSchema,
                            type: 'group'
                        };
                    })
            ];
        }, []);

        for (const consultancyUser of consultancyUsers) {
            const userExists = itemsWithSchema.some(item => {
                const userEmail = item.email?.trim()?.toLowerCase();

                const consultancyUserEmail = consultancyUser.email
                    ?.trim()
                    ?.toLowerCase();
                return Boolean(
                    userEmail &&
                        consultancyUserEmail &&
                        userEmail === consultancyUserEmail
                );
            });

            if (userExists) {
                continue;
            }

            itemsWithSchema.push({
                email: consultancyUser.email,
                firstName: consultancyUser.firstName,
                id: consultancyUser.id,
                label: `${consultancyUser.firstName} ${consultancyUser.lastName}`,
                lastName: consultancyUser.lastName,
                query: `${consultancyUser.firstName} ${consultancyUser.lastName}`,
                roles: [],
                schema: usedSchema,
                type: 'user'
            });
        }

        return itemsWithSchema;
    };

export function useGetCommentUserTagging({
    excludeCertificationRole,
    usedSchema
}) {
    const { useConsultancyUsers } = useGlobalStateHooks();
    const [consultancyUsers] = useConsultancyUsers();

    return useQuery({
        queryFn: async () => {
            const gqlQuery = buildGqlQuery({
                query: () => getCommentUserTaggingGql({ schemas: [usedSchema] })
            });
            const client = gqlClient({
                accessToken: localStorage.accessToken,
                url: process.env.REACT_APP_GRAPHQL_URL
            });

            const res = await client.request(gqlQuery);
            return res;
        },
        queryKey: queryKeys.users.getCommentUserTagging({
            excludeCertificationRole,
            schemas: [usedSchema]
        }),
        select: tranformData({
            consultancyUsers,
            excludeCertificationRole,
            usedSchema
        })
    });
}
