import { forwardRef, useCallback, useEffect } from 'react';

import InfoHollowButton from 'components/InfoHollowButton';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import styles from './styles.module.scss';

const MarkReopenButton = forwardRef(
    ({ activityId, activitySchema, className = '', isDisabled = false, onClick, userId }, ref) => {
        const { useActivities } = useGlobalStateHooks();
        const { REOPEN } = useTranslation();

        const [_activities, setActivities] = useActivities();
        const [reopenedActivity, reopenActivity] = useData(activitySchema);

        const handleClose = useCallback(() => {
            const dataToSubmit = {
                activityId,
                userId: parseInt(userId)
            }
            reopenActivity(dataToSubmit);
        }, [activityId, reopenActivity, userId]);

        useEffect(() => {
            if (!reopenedActivity) {
                return;
            }
    
            if (isFunction(onClick)) {
                onClick(reopenedActivity);
            }

            setActivities({});
    
            window.location.reload();
        }, [reopenedActivity, onClick, setActivities]);

        return (
            <InfoHollowButton
                className={`${className} ${styles.reopenButton}`}
                disabled={isDisabled}
                onClick={handleClose}
                ref={ref}
                text={REOPEN}
            />
        );
    }
);

export default MarkReopenButton;
