import app from 'components/App/state';
import activities from 'components/Activities/state';
import activity from 'pages/Activity/state';
import activityDetailsDropdownChanging from 'components/ActivityDetailsPanel/state';
import activityDetailsPanel from 'components/ActivityDetailsPanel/state';
import activityFilters from 'components/AddActivitiesPanel/state';
import activityId from 'components/CloseActivityAndCreateNewActivityMenuListItem/state';
import activityMetaPanel from 'components/ActivityMetaPanel/state';
import activityStatus from 'pages/Activity/state';
import activityTypeDetails from 'pages/ActivityType/state';
import activityTypeDetailsPanel from 'components/ActivityTypeDetailsPanel/state';
import activityTypeFormSection from 'pages/ActivityType/state';
import activityTypes from 'components/ActivityTypes/state';
import addActivity from 'pages/AddActivity/state';
import addActivityType from 'pages/AddActivityType/state';
import addExistingForm from 'components/ActivityTypeDetailsPanel/state';
import addForm from 'pages/AddForm/state';
import addFormAnswer from 'components/AddActivityQuestions/state';
import addGroup from 'pages/AddGroup/state';
import addLogEntryModal from 'components/AddLogEntryModal/state';
import addMarketingVersion from 'components/AddVersionLink/state';
import addPerson from 'pages/AddPerson/state';
import updatePerson from 'pages/EditPerson/state';
import addPolicy from 'pages/AddPolicy/state';
import addPolicySectionModal from 'components/AddPolicySectionModal/state';
import addRequest from 'pages/AddMarketingRequest/state';
import authenticated from 'components/Authenticated/state';
import authenticatePanel from 'components/AuthenticatePanel/state';
import avatars from 'components/Avatar/state';
import caseCreationReasonModal from 'components/CaseCreationReasonModal/state';
import caseDetails from 'pages/Case/state';
import caseDetailsPanel from 'components/CaseDetailsPanel/state';
import caseFilters from 'components/CaseManagementPanel/state';
import caseId from 'components/CloseActivityAndCreateNewActivityMenuListItem/state';
import caseTypeCounts from 'components/CasePanel/state';
import cases from 'components/CaseManagementPanel/state';
import closeActivityAndCreateCaseMenuListItem from 'components/CloseActivityAndCreateCaseMenuListItem/state';
import closeActivityAndCreateNewActivityMenuListItem from 'components/CloseActivityAndCreateNewActivityMenuListItem/state';
import closeActivityAndAddLogEntryMenuListItem from 'components/CloseActivityAndAddLogEntryMenuListItem/state';
import closeActivityMenuListItem from 'components/CloseActivityMenuListItem/state';
import closeCaseAndAddLogEntryMenuListItem from 'components/CloseCaseAndAddLogEntryMenuListItem/state';
import closeCaseAndCreateNewActivityMenuListItem from 'components/CloseCaseAndCreateNewActivityMenuListItem/state';
import closeCaseMenuListItem from 'components/CloseCaseMenuListItem/state';
import completedActivityInProgressDetailed from 'components/InProgressDetailedListItem/state';
import completedActivityInProgressSummarized from 'components/InProgressSummarizedListItem/state';
import completedActivityOverdueDetailed from 'components/OverdueDetailedListItem/state';
import completedActivityOverdueSummarized from 'components/OverdueSummarizedListItem/state';
import completedActivityUpcomingDetailed from 'components/UpcomingDetailedListItem/state';
import completedActivityUpcomingSummarized from 'components/UpcomingSummarizedListItem/state';
import complianceEntryDetailsPanel from 'components/ComplianceEntryDetailsPanel/state';
import createCaseModal from 'components/CreateCaseModal/state';
import deleteActivityModal from 'components/DeleteActivityModal/state';
import deleteCaseModal from 'components/CaseTable/state';
import deleteSectionModal from 'components/PolicyMappingTable/state';
import editPolicySectionModal from 'components/EditPolicySectionModal/state';
import entities from 'components/AddActivityMetaPanel/state';
import filters from 'components/Authenticated/state';
import flaggedActivity from 'components/ListItemDetailsButton/state';
import forms from 'components/FormsManagementPanel/state';
import groupDetails from 'pages/Group/state';
import groupDetailsPanel from 'components/GroupDetailsPanel/state';
import logDetails from 'pages/ComplianceLogEntry/state';
import loginPanel from 'components/LoginPanel/state';
import logo from 'components/Logo/state';
import logs from 'components/LogEntriesPanel/state';
import markCompleteButton from 'components/MarkCompleteButton/state';
import markReviewedButton from 'components/MarkReviewedButton/state';
import marketingRequestForm from 'components/MarketingRequestForm/state';
import marketingRequestMetaPanel from 'components/MarketingRequestMetaPanel/state';
import people from 'components/EmployeesPanel/state';
import policies from 'components/ActivityForm/state';
import policiesList from 'components/DocumentManagementPolicyTab/state';
import policy from 'pages/Policy/state';
import policyDetails from 'pages/Policy/state';
import reopenButton from 'components/MarkReopenButton/state';
import repositoryList from 'components/DocumentManagementRepositoryTab/state';
import repositorysFilteredList from 'components/DocumentManagementRepositoryTab/state';
import requestApproveModal from 'components/ApproveRequestModal/state';
import requestCounts from 'components/MarketingPanel/state';
import requestRejectModal from 'components/RejectModal/state';
import requestUpdateModal from 'components/RequestUpdateModal/state';
import requests from 'components/MarketingRequestsPanel/state';
import sectionsList from 'components/DocumentManagementSectionsTab/state';
import sideLettersList from 'components/DocumentManagementSideLetterTab/state';
import supervisors from 'pages/Group/state';
import unflaggedActivity from 'components/ListItemDetailsButton/state';
import updateActivityName from 'components/ActivityDetailsPanel/state';
import updateActivityRecurrence from 'components/EditRecurrenceModal/state';
import updateActivityTypeCategory from 'components/ActivityTypeDetailsPanel/state';
import updateActivityTypeDetailsPanel from 'components/ActivityTypeDetailsPanel/state';
import updateActivityTypeName from 'components/ActivityTypeDetailsPanel/state';
import updateActivityTypePolicies from 'components/ActivityTypeDetailsPanel/state';
import updateActivityTypeRiskLevel from 'components/ActivityTypeDetailsPanel/state';
import updateActivityTypeVisibleTo from 'components/ActivityTypeDetailsPanel/state';
import updateActivityUser from 'components/ActivityMetaPanel/state';
import updateCaseName from 'components/CaseDetailsPanel/state';
import updateCaseTypeMetaPanelName from 'components/CaseTypeMetaPanel/state';
import updateDescription from 'components/ComplianceEntryDetailsPanel/state';
import updateEndDate from 'components/ActivityMetaPanel/state';
import updateExistingForm from 'components/ActivityTypeDetailsPanel/state';
import updateGroupDescription from 'components/GroupDetailsPanel/state';
import updateGroupMembers from 'components/GroupMembersPanel/state';
import updateGroupName from 'components/GroupDetailsPanel/state';
import updateGroupSupervisors from 'components/GroupDetailsPanel/state';
import updateHierarchy from 'components/ActivityMetaPanel/state';
import updateInstructions from 'components/ActivityDetailsPanel/state';
import updateRecurrenceEndDateModal from 'components/UpdateRecurrenceEndDateModal/state';
import updatePolicyReference from 'components/ActivityDetailsPanel/state';
import updatePublishedVersion from 'components/PublishPolicyVersionButton/state';
import updateRiskLevel from 'components/CaseDetailsPanel/state';
import updateSectionDescription from 'components/PolicyMappingTableRow/state';
import updateSectionName from 'components/PolicyMappingTableRow/state';
import updateSendEmail from 'components/ActivityMetaPanel/state';
import updateStartDate from 'components/ActivityMetaPanel/state';
import users from 'components/AddActivityMetaPanel/state';

const state = {
    activities,
    activity,
    activityDetailsDropdownChanging,
    activityDetailsPanel,
    activityFilters,
    activityId,
    activityMetaPanel,
    activityStatus,
    activityTypeDetails,
    activityTypeDetailsPanel,
    activityTypeFormSection,
    activityTypes,
    addActivity,
    addActivityType,
    addExistingForm,
    addForm,
    addFormAnswer,
    addGroup,
    addLogEntryModal,
    addMarketingVersion,
    addPerson,
    addPolicy,
    addPolicySectionModal,
    addRequest,
    app,
    authenticatePanel,
    authenticated,
    avatars,
    caseCreationReasonModal,
    caseDetails,
    caseDetailsPanel,
    caseFilters,
    caseId,
    caseTypeCounts,
    cases,
    closeActivityAndAddLogEntryMenuListItem,
    closeActivityAndCreateCaseMenuListItem,
    closeActivityAndCreateNewActivityMenuListItem,
    closeActivityMenuListItem,
    closeCaseAndAddLogEntryMenuListItem,
    closeCaseAndCreateNewActivityMenuListItem,
    closeCaseMenuListItem,
    completedActivityInProgressDetailed,
    completedActivityInProgressSummarized,
    completedActivityOverdueDetailed,
    completedActivityOverdueSummarized,
    completedActivityUpcomingDetailed,
    completedActivityUpcomingSummarized,
    complianceEntryDetailsPanel,
    createCaseModal,
    deleteActivityModal,
    deleteCaseModal,
    deleteSectionModal,
    editPolicySectionModal,
    entities,
    filters,
    flaggedActivity,
    forms,
    groupDetails,
    groupDetailsPanel,
    logDetails,
    loginPanel,
    logo,
    logs,
    markCompleteButton,
    markReviewedButton,
    marketingRequestForm,
    marketingRequestMetaPanel,
    people,
    policies,
    policiesList,
    policy,
    policyDetails,
    reopenButton,
    repositoryList,
    repositorysFilteredList,
    requestApproveModal,
    requestCounts,
    requestRejectModal,
    requestUpdateModal,
    requests,
    sectionsList,
    sideLettersList,
    supervisors,
    unflaggedActivity,
    updateActivityName,
    updateActivityRecurrence,
    updateActivityTypeCategory,
    updateActivityTypeDetailsPanel,
    updateActivityTypeName,
    updateActivityTypePolicies,
    updateActivityTypeRiskLevel,
    updateActivityTypeVisibleTo,
    updateActivityUser,
    updateCaseName,
    updateCaseTypeMetaPanelName,
    updateDescription,
    updateEndDate,
    updateExistingForm,
    updateGroupDescription,
    updateGroupMembers,
    updateGroupName,
    updateGroupSupervisors,
    updateHierarchy,
    updateInstructions,
    updatePerson,
    updatePolicyReference,
    updatePublishedVersion,
    updateRecurrenceEndDateModal,
    updateRiskLevel,
    updateSectionDescription,
    updateSectionName,
    updateSendEmail,
    updateStartDate,
    users
};

export default state;
