import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('casePage');

    return {
        DELETED: t('deleted'),
        DETAILS: t('details'),
        HISTORY: t('history'),
        META: t('meta'),
    };
};

export default useTranslation;
