import { useQuery, gql } from '@apollo/client';

const query = () => gql`
query getRoles {
    role{
        id
        name
        description
    }
  }
`;

const useGroupsAndRoles = () => {
    return useQuery(query(), {
        fetchPolicy: 'no-cache'
    });
};

export default useGroupsAndRoles;