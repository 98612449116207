import ContentPanel from 'components/ContentPanel';
import Menu from 'components/Menu';

import usePopperState from 'hooks/usePopperState';

import styles from './styles.module.scss';

const WithTooltip = props => {
    const { className = '', children, placement = 'bottom', text = '', showTooltip=true } = props;

    const { handleClick, onClose, referenceElement } = usePopperState();

    return (
        <div
            className={className}
            onMouseEnter={handleClick}
            onMouseLeave={onClose}
        >
            {children}

            {referenceElement && showTooltip && (
                <Menu className={styles.overlay} placement={placement} referenceElement={referenceElement}>
                    <ContentPanel
                        className={`${styles.content} ${
                            className === 'policyReference'
                                ? styles.policyReference
                                : ''
                        }`}
                    >
                        {text}
                    </ContentPanel>
                </Menu>
            )}
        </div>
    );
};

export default WithTooltip;
