import styles from './styles.module.scss';
import { forwardRef } from 'react';

const Pill = forwardRef(({ className = '', label = '' }, ref) => {
    return (
        <div className={`${styles.pill} ${className}`} ref={ref}>
            {label}
        </div>
    );
});

export default Pill;
