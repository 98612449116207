import { Droppable, Draggable } from "react-beautiful-dnd";
import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';

import styles from './styles.module.scss';

import Dropdown from 'components/Dropdown';
import DynamicRadioButtons from 'components/DynamicRadioButtons';
import Label from 'components/Label';
import PanelTitle from 'components/PanelTitle';
import TextInput from 'components/TextInput';
import TextBox from 'components/TextBox';
import CheckboxWithLabel from 'components/CheckboxWithLabel';
import MoveIcon from 'components/icons/MoveIcon';
import CloseIcon from 'components/icons/CloseSubtleIcon';
import Buttonize from 'components/Buttonize';
import AddFormDetailsConditionalElement from 'components/AddFormDetailsConditionalElement';
import TextareaInput from 'components/TextareaInput';
import { FormTable } from "components/form-table/form-table";

const AddFormDetailsPanel = ({ className = '', formElements,onDeleteFormComponent, onRegisterFormComponent, setFormElements, setFormNotes, setFormTitle }) => {

    const handleFormNameChange = titleField => {
        setFormTitle(titleField);
    };

    const handleFormNotesChange = notesField => {
        setFormNotes(notesField);
    };

    const handleRemoveElement = (_, index) => {
        const deletedElement = formElements[index];
        const formComponent = deletedElement?.formComponent;
        const formIndex = deletedElement?.index;
        const formElementsToModify = [...formElements];
        formElementsToModify.splice(index, 1);
        const changedElements = [...formElementsToModify];

        for (let i = 0; i < changedElements.length; i++) {
            if(changedElements[i]['formComponent'] === formComponent){
                if(changedElements[i]['index'] > formIndex){
                    changedElements[i]['index'] = changedElements[i]['index'] - 1;
                }
            }
        }
        setFormElements(changedElements);
    };

    return (
        <ContentPanel
            className={`${className} ${styles.addFormDetailsPanel}`}
        >
            <PanelBody className={styles.panelBody}>
                <TextInput
                    className={styles.formName}
                    label={"Form Name"}
                    onChange={handleFormNameChange}
                />

                <TextBox
                    label={'Form Notes'}
                    onChange={handleFormNotesChange}
                />

                <PanelTitle text={'Build Your Form Here'} />

                <Droppable className={styles.panelBody} droppableId={'newForm'} key={'newForm'}>
                    {(provided, snapshot) => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  background: snapshot.isDraggingOver
                                    ? "#f9f9f9"
                                    : "inherit",
                                  minHeight: 800,
                                  padding: 4,
                                  width: '100%'
                                }}
                            >
                                {formElements && formElements.map((element, index) => {
                                    return(
                                    <Draggable
                                        draggableId={element.id}
                                        index={index}
                                        key={element.id}
                                    >
                                        {(provided, _snapshot) => {
                                            if(element?.formComponent === 'newForm'){
                                                return (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        style={{
                                                            padding: 16,
                                                            userSelect: "none",
                                                            ...provided.draggableProps.style
                                                        }}
                                                    >
                                                        {element.type === 'title' &&
                                                            <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                <span
                                                                    className={styles.icon}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <MoveIcon className={styles.iconComponent}/>
                                                                </span>

                                                                <h2>{element.text}</h2>

                                                                <Buttonize
                                                                    className={styles.icon}
                                                                    onClick={res => handleRemoveElement(res, index)}
                                                                >
                                                                    <CloseIcon className={styles.closeIconComponent}/>
                                                                </Buttonize>
                                                            </div>
                                                        }
                                                        
                                                        {element.type === 'text' &&
                                                            <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                <span
                                                                    className={styles.icon}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <MoveIcon className={styles.iconComponent}/>
                                                                </span>

                                                                <p>{element.text}</p>

                                                                <Buttonize
                                                                    className={styles.icon}
                                                                    onClick={res => handleRemoveElement(res, index)}
                                                                >
                                                                    <CloseIcon className={styles.closeIconComponent}/>
                                                                </Buttonize>
                                                            </div>
                                                        }
                                                        
                                                        {element.type === 'textInput' &&
                                                            <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                <span
                                                                    className={styles.icon}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <MoveIcon className={styles.iconComponent}/>
                                                                </span>

                                                                <TextInput
                                                                    label={element.text}
                                                                />

                                                                <Buttonize
                                                                    className={styles.icon}
                                                                    onClick={res => handleRemoveElement(res, index)}
                                                                >
                                                                    <CloseIcon className={styles.closeIconComponent}/>
                                                                </Buttonize>
                                                            </div>
                                                        }
                                                        
                                                        {element.type === 'textAreaInput' &&
                                                            <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                <span
                                                                    className={styles.icon}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <MoveIcon className={styles.iconComponent}/>
                                                                </span>

                                                                <TextareaInput
                                                                    label={element.text}
                                                                />

                                                                <Buttonize
                                                                    className={styles.icon}
                                                                    onClick={res => handleRemoveElement(res, index)}
                                                                >
                                                                    <CloseIcon className={styles.closeIconComponent}/>
                                                                </Buttonize>
                                                            </div>
                                                        }
                                                        
                                                        {element.type === 'radioInput' &&
                                                            <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                <span
                                                                    className={styles.icon}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <MoveIcon className={styles.iconComponent}/>
                                                                </span>

                                                                <div>
                                                                    <Label text={element.text} />

                                                                    <DynamicRadioButtons
                                                                        className={styles.radioButtons} 
                                                                        items={element.options}
                                                                    />
                                                                </div>

                                                                <Buttonize
                                                                    className={styles.icon}
                                                                    onClick={res => handleRemoveElement(res, index)}
                                                                >
                                                                    <CloseIcon className={styles.closeIconComponent}/>
                                                                </Buttonize>
                                                            </div>
                                                        }
                                                        
                                                        {element.type === 'dropDownInput' &&
                                                            <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                <span
                                                                    className={styles.icon}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <MoveIcon className={styles.iconComponent}/>
                                                                </span>

                                                                <Dropdown items={element.options} label={element.text} />

                                                                <Buttonize
                                                                    className={styles.icon}
                                                                    onClick={res => handleRemoveElement(res, index)}
                                                                >
                                                                    <CloseIcon className={styles.closeIconComponent}/>
                                                                </Buttonize>
                                                            </div>
                                                        }
                                                        
                                                        {element.type === 'checkBoxInput' &&
                                                            <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                <span
                                                                    className={styles.icon}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <MoveIcon className={styles.iconComponent}/>
                                                                </span>

                                                                <div>
                                                                    <Label text={element.text} />

                                                                    {element.options.map((option, _index) => {
                                                                        return(
                                                                            <div>
                                                                                <CheckboxWithLabel text={option.label} />
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>

                                                                <Buttonize
                                                                    className={styles.icon}
                                                                    onClick={res => handleRemoveElement(res, index)}
                                                                >
                                                                    <CloseIcon className={styles.closeIconComponent}/>
                                                                </Buttonize>
                                                            </div>
                                                        }
                                                        
                                                        {element.type === 'conditional' &&

                                                            <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                <span
                                                                    className={styles.icon}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <MoveIcon className={styles.iconComponent}/>
                                                                </span>

                                                                <h4>Conditional on field '{element.text}'</h4>

                                                                <div></div>

                                                                <AddFormDetailsConditionalElement element={element} formElements={formElements} index={index} setFormElements={setFormElements} />

                                                                <Buttonize
                                                                    className={styles.icon}
                                                                    onClick={res => handleRemoveElement(res, index)}
                                                                >
                                                                    <CloseIcon className={styles.closeIconComponent}/>
                                                                </Buttonize>
                                                            </div>
                                                        }

                                                        {element.type === 'table' &&

                                                            <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                <span
                                                                    className={styles.icon}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <MoveIcon className={styles.iconComponent}/>
                                                                </span>

                                                                <FormTable editColumns editRows onFormComponentRef={onRegisterFormComponent(element.id)} />
                                                                
                                                                <Buttonize
                                                                    className={styles.icon}
                                                                    onClick={res => {
                                                                        onDeleteFormComponent(element.id);
                                                                        handleRemoveElement(res, index)}}
                                                                >
                                                                    <CloseIcon className={styles.closeIconComponent}/>
                                                                </Buttonize>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }else{
                                                return(
                                                    <div
                                                        ref={provided.innerRef}
                                                    >
                                                    </div>
                                                )
                                            }
                                        }}
                                    </Draggable>
                                )})}
                                
                            {provided.placeholder}
                            </div>
                        )
                    }}
                </Droppable>
            </PanelBody>
        </ContentPanel>
    );
};

export default AddFormDetailsPanel;
