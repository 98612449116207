import { useQuery } from '@apollo/client';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import generateQueryFromDict from 'utilities/generateQueryFromDict';

const caseQuery = (tenantSchema, userGQL, usersGQL) => `
  ${tenantSchema}_case (where: {deleted: {_eq: false}}) {
    id
    due_date
    case_name
    form_id
    caseTypeByCaseType {
      name
      dropdown
    }
    created_date
    tenant_user {
      id
      ${userGQL}
    }
    closed
    closed_date
    creation_reason
    dropdown_selections
    action_plan
    proposed_solution
    resolution
    risk_label
    user_cases {
      tenant_user {
        id
        ${usersGQL}
      }
    }
    case_attachments {
      name
      file
    }
    case_comments {
      created_at
      text
      user_case_comments {
        tenant_user {
          user_${tenantSchema} {
            first_name
            last_name
          }
        }
      }
    }
    case_entities {
      entity {
        name
      }
    }
    case_histories {
      message
      tenant_user {
        id
        ${userGQL}
      }
    }
    case_policies {
      document_section_id
      document {
        description
        created_by
        policy_sections {
          section {
            name
            description
            file
            id
          }
        }
      }
    }
    case_user_involveds {
      tenant_user {
        id
        ${usersGQL}
      }
    }
  }
`;

const formQuery = (tenantSchema) => `
  ${tenantSchema}_form {
    id
    name
    form_questions {
      question {
        id
        questionId
        formComponent
        name
        type
        choices {
          text
        }
      }
      case_form_answers {
        case_id
        question_id
        answer
        tenant_user {
          id
        }
      }
    }
  }
`;

const useData = () => {
  const { useQuerySchemas, useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
  const [querySchemas] = useQuerySchemas();
  const [schema] = useSchema();
  const [tenants] = useTenants();
  const [userSetSchema] = useUserSetSchema();
  const usedSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;

  let userGQL = '';
  let usersGQL = '';

  if (querySchemas.length > 0) {
    querySchemas.forEach(s => {
      usersGQL += `users_${s.value} {
        first_name
        last_name
      }`;
      userGQL += `user_${s.value} {
        first_name
        last_name
      }`;
    });
  } else if (usedSchema) {
    usersGQL += `users_${usedSchema} {
      first_name
      last_name
    }`;
    userGQL += `user_${usedSchema} {
      first_name
      last_name
    }`;
  }

  const { data: caseData, loading: caseLoading, error: caseError } = useQuery(generateQueryFromDict({
    query: caseQuery,
    schema: usedSchema,
    tenants,
    userGQL,
    usersGQL
  }), { fetchPolicy: 'no-cache' });

  const { data: formData, loading: formLoading, error: formError } = useQuery(generateQueryFromDict({
    query: formQuery,
    schema: usedSchema,
    tenants
  }), { fetchPolicy: 'no-cache' });

  let combinedData = [];
  if (caseData && formData) {
    combinedData = caseData[`${usedSchema}_case`].map(caseItem => {
      const formDetails = formData[`${usedSchema}_form`].find(form => form.id === caseItem.form_id);
      return {
        ...caseItem,
        caseTypeByCaseType: {
          ...caseItem.caseTypeByCaseType,
          form: formDetails ? formDetails : {}
        }
      };
    });
  }

  return {
    data: combinedData,
    error: caseError || formError,
    loading: caseLoading || formLoading,
  };
};

export default useData;
