import {useEffect} from 'react';

import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';
import TextInput from 'components/TextInput';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';
import WithLabel from 'components/WithLabel';
import ToggleWithLabel from 'components/ToggleWithLabel';
import Dropdown from 'components/Dropdown';

const ALL_ROLE_NAME = 'all';

const PersonalInformationPanel = ({
    className = '',
    errorMessage,
    form,
    rolesData,
    isAllRoleSelected,
    isEditingMode = false,
    setIsAllRoleSelected,
    people = {},
}) => {
    const { handleError, handleSubmit, handleValid, initializeFormData } = form;

    const { EMAIL_ADDRESS, FIRST_NAME, LAST_NAME, ROLE } = useTranslation();

    useEffect(() => {
        if (people && Object.keys(people).length > 0) {
            initializeFormData({
                emailAddress: people.email,
                firstName: people.firstName,
                lastName: people.lastName,
            });
        }
    }, [people, initializeFormData]);

    const rolesItems = !isEditingMode && rolesData
        .filter(role => role.name !== ALL_ROLE_NAME)
        .map(role => ({ label: role.description, value: role.id }))

    return (
        <ContentPanel
            className={`${className} ${styles.personalInformationPanel}`}
        >
            <PanelBody className={styles.panelBody}>
                {errorMessage && (
                    <div className={styles.errorMessage}>{errorMessage}</div>
                )}

                <TextInput
                    label={FIRST_NAME + ' *'}
                    onChange={handleValid('firstName')}
                    onError={handleError('firstName')}
                    onSubmit={handleSubmit('firstName')}
                    value={people?.firstName || ''}
                />

                <TextInput
                    label={LAST_NAME + ' *'}
                    onChange={handleValid('lastName')}
                    onError={handleError('lastName')}
                    onSubmit={handleSubmit('lastName')}
                    value={people?.lastName || ''}
                />

                <TextInput
                    label={EMAIL_ADDRESS + ' *'}
                    onChange={handleValid('emailAddress')}
                    onError={handleError('emailAddress')}
                    onSubmit={handleSubmit('emailAddress')}
                    value={people?.email || ''}
                />

                {!isEditingMode && (
                    <>
                        <WithLabel text={'See all data'}>
                            <ToggleWithLabel
                                className={styles.toggleWithLabel}
                                isActive={!isAllRoleSelected}
                                label="See Own"
                                onToggle={() => setIsAllRoleSelected(!isAllRoleSelected)}
                                sublabel="See All"
                            />
                        </WithLabel>

                        <Dropdown
                            items={rolesItems}
                            label={ROLE + ' *'}
                            onChange={handleValid('role')}
                            onError={handleError('role')}
                            onSubmit={handleSubmit('role')}
                        />
                    </>
                )}

            </PanelBody>
        </ContentPanel>
    );
};

export default PersonalInformationPanel;
