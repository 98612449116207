import { useQuery } from '@apollo/client';

import generateQueryFromDict from 'utilities/generateQueryFromDict';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = (tenantSchema, userGQL) => `
    ${tenantSchema}_document(where: {document_type: {_eq: "side_letter"}}){
      id
      name
      is_active
      risk_label
      applied_tos {
        group {
          name
        }
        tenant_user {
          ${userGQL}
        }
      }
      document_versions{
        effective_date
        is_active_version
        is_draft
        is_published
        version_number
      }
      document_categories {
        category {
          id
          label
        }
      }
      policy_sections {
        section {
          name
        }
      }
    }
`;

const useData = () => {
  const { useQuerySchemas, useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
  const [querySchemas] = useQuerySchemas();
  const [schema] = useSchema();
  const [tenants] = useTenants();
  const [userSetSchema] = useUserSetSchema();
  const usedSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
  var userGQL = '';
  if(querySchemas.length > 0){
      for(const s of querySchemas){
          userGQL += `user_${s.value} {
              id
              first_name
              last_name
          }`
      }
  }else if(usedSchema){
      userGQL += `user_${usedSchema} {
          id
          first_name
          last_name
      }`
  }
  const combineQuery = generateQueryFromDict({query, schema:usedSchema, tenants, userGQL});
  return useQuery(combineQuery, { fetchPolicy: 'no-cache' });
};

export default useData;
