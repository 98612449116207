import { useCallback, useEffect, useMemo, useState } from 'react';

import AllMeToggle from 'components/AllMeToggle';
import CaseTable from 'components/CaseTable';
import ContentPanel from 'components/ContentPanel';
import DatePicker from 'components/DatePicker';
import Dropdown from 'components/Dropdown';
import PanelBody from 'components/PanelBody';
import SearchInput from 'components/SearchInput';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useCases from './hooks/useCases';
import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import normalizeData from './data';

import styles from './styles.module.scss';
import useFilter from './hooks/useFilter';

import lexigraphicalSort from 'utilities/lexigraphicalSort';
import filterDataByName from 'utilities/searchFilterByName';
import { useExportCases } from 'hooks/useExportCases';
import {useSetFilteredCasesData} from 'components/CaseManagementPanel/hooks/useFilteredCases';

const CaseManagementPanel = props => {
    const { caseManagementData=[], className = '',  } = props;

    const { useEndDateFilter, useEntityFilter, useIsAllFilter, useQuerySchemas, useRiskLabelFilter, useSchema, useStartDateFilter, useTenants, useUserFilter, useUserId } = useGlobalStateHooks();
		const {exportDetailedCases} = useExportCases()
    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    const [tenants] = useTenants();
		const [userId] = useUserId();

    const [overdueCases, setOverdueCases] = useState();
    const [pendingCases, setPendingCases] = useState();
    const [closedCases, setClosedCases] = useState();
    const [searchTerm, setSearchTerm] = useState('');


    const [cases, setCases] = useCases();
    const [dueDateFilter, setDueDateFilter] = useEndDateFilter();
    const [entityFilter, setEntityFilter] = useEntityFilter();
    const [isAll, setIsAll] = useIsAllFilter();
    const [riskFilter, setRiskFilter] = useRiskLabelFilter();
    const [startDateFilter, setStartDateFilter] = useStartDateFilter();
    const [typeFilter, setTypeFilter] = useFilter();
    const [userFilter, setUserFilter] = useUserFilter();

    const setFilteredCasesData = useSetFilteredCasesData();

    const handleToggle = useCallback(isOn => {
        setIsAll(!isOn);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!dueDateFilter){
            setDueDateFilter(new Date(new Date().setDate(new Date().getDate() + 365)));
        }
        if (!startDateFilter){
            setStartDateFilter(new Date(new Date().setDate(new Date().getDate() - 365)));
        }
        // eslint-disable-next-line
    }, [])

    const { loading, error, data } = useData();

    const {
        ASSIGNED_TO,
        CASE_TYPE,
        DUE_DATE,
        ENTITIES,
        RISK_LABEL,
        SEARCH,
        START_DATE,
        VIEW_ALL } = useTranslation();

    const typeItems = useMemo(() => {
        if (!cases) {
            return [];
        }

        const dataItems = cases.reduce(
            (accumulator, { caseType }) => {
                if (!(caseType in accumulator.items)) {
                    accumulator.items[caseType] = 0;
                }
                return accumulator;
            },
            { items: {} }
        );

        const { items } = dataItems;

        const options = Object.keys(items).map(key => ({
            label: key,
            value: key
        }));

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [
            defaultItem,
            ...lexigraphicalSort(options, ['label'])
        ];
    }, [VIEW_ALL, cases]);

    const userItems = useMemo(() => {
        if (!cases) {
            return [];
        }

        const options = cases.reduce(
            (accumulator, { assignedTo }) => {
                for(const assigned of assignedTo){
                    accumulator.push({label: `${assigned?.firstName} ${assigned?.lastName}`, value: assigned?.id});
                }

                return accumulator;
            },[]
        );

        const uniqueOptions = options.filter((value, index) => {
            const _value = JSON.stringify(value);
            return index === options.findIndex(obj => {
                return JSON.stringify(obj) === _value;
            });
        });

        const sortedOptions = uniqueOptions.sort((a, b) => {
            if (a.label > b.label) {
                return 1;
            }
            if (a.label < b.label) {
                return -1;
            }
            return 0;
        });

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [defaultItem, ...sortedOptions];
    }, [VIEW_ALL, cases]);

    const entityItems = useMemo(() => {
        if (!cases) {
            return [];
        }

        const dataItems = cases.reduce(
            (accumulator, { entityTags }) => {
                for (const entity of entityTags){
                    if (!(entity in accumulator.items)) {
                        accumulator.items[entity] = 0;
                    }
                }

                return accumulator;
            },
            { items: {} }
        );

        const { items } = dataItems;

        const options = Object.keys(items).map(key => ({
            label: key,
            value: key
        }));

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [defaultItem, ...options];
    }, [VIEW_ALL, cases]);

    const riskItems = useMemo(() => {
        if (!cases) {
            return [];
        }

        const dataItems = cases.reduce(
            (accumulator, { riskLabel }) => {
                if (!(riskLabel in accumulator.items)) {
                    accumulator.items[riskLabel] = 0;
                }
                return accumulator;
            },
            { items: {} }
        );

        const { items } = dataItems;

        const options = Object.keys(items).map(key => ({
            label: !key || key === 'null' ? "Neutral" : key.charAt(0).toUpperCase() + key.substring(1),
            value: !key || key === 'null' ? "neutral" : key
        }));

        const uniqueOptions = options.filter((value, index) => {
            const _value = JSON.stringify(value);
            return index === options.findIndex(obj => {
                return JSON.stringify(obj) === _value;
            });
        });

        const sortOrder = ['Critical', 'High', 'Medium', 'Low', 'Neutral']

        const sortedOptions = uniqueOptions.sort(function(a, b) {
            return sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label);
        });

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [defaultItem, ...sortedOptions];
    }, [VIEW_ALL, cases]);

    if ((typeItems.filter(e => e.value === typeFilter).length === 0 && typeFilter === 'View All') || (Array.isArray(typeFilter) && typeFilter.length===0)) {
        setTypeFilter(null);
    }

    if ((userItems.filter(e => e.value === userFilter).length === 0 && userFilter === 'View All') || (Array.isArray(userFilter) && userFilter.length===0)) {
        setUserFilter(null);
    }

    if ((entityItems.filter(e => e.value === entityFilter).length === 0 && entityFilter === 'View All') || (Array.isArray(entityFilter) && entityFilter.length===0)) {
        setEntityFilter(null);
    }

    if ((riskItems.filter(e => e.value === riskFilter).length === 0 && riskFilter === 'View All') || (Array.isArray(riskFilter) && riskFilter.length===0)) {
        setRiskFilter(null);
    }

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                querySchemas,
                schema,
                tenants
            });

            const sortedCases = normalizedData.sort(function(a,b){
                return new Date(a.dueDate) - new Date(b.dueDate);
            });

            const closedCases = sortedCases.filter(
                ({ closed }) => closed
            );

            const notClosedCases = sortedCases.filter(
                ({ closed }) => !closed
            );

            const overdueCases = notClosedCases
            .filter(({ dueDate }) => new Date(dueDate) < new Date())
            .filter(({ dueDate }) => 
                (!startDateFilter || new Date(dueDate) >= startDateFilter) &&
                (!dueDateFilter || new Date(dueDate) <= dueDateFilter)
            );

            const pendingCases = notClosedCases
            .filter(({ dueDate }) => new Date(dueDate) >= new Date())
            .filter(({ dueDate }) => 
                (!startDateFilter || new Date(dueDate) >= startDateFilter) &&
                (!dueDateFilter || new Date(dueDate) <= dueDateFilter)
            );

            const typeFilteredClosedCases = typeFilter
                ? closedCases.filter(
                      ({ caseType }) => caseType === typeFilter
                  )
                : closedCases;

            const userFilteredClosedCases = userFilter
                ? typeFilteredClosedCases.filter(
                        ({ assignedTo }) => assignedTo.some(arrVal => arrVal?.id === userFilter)
                    )
                : typeFilteredClosedCases;

            const startDateFilteredClosedCases = startDateFilter
                ? userFilteredClosedCases.filter(
                    ({ dueDate }) => new Date(dueDate) >= startDateFilter
                )
                : userFilteredClosedCases;

            const dueDateFilteredClosedCases = dueDateFilter
                ? startDateFilteredClosedCases.filter(
                    ({ dueDate }) => new Date(dueDate) <= dueDateFilter
                )
                : startDateFilteredClosedCases;

            const entityFilteredClosedCases = entityFilter
                ? dueDateFilteredClosedCases.filter(
                        ({ entityTags }) => entityTags.some(arrVal => arrVal === entityFilter)
                    )
                : dueDateFilteredClosedCases;

            const riskFilteredClosedCases = riskFilter
                ? entityFilteredClosedCases.filter(
                        ({ riskLabel }) => {
                            if (riskFilter === 'neutral'){
                                return riskLabel === '' || !riskLabel
                            } else {
                                return riskLabel === riskFilter
                            }
                        }
                    )
                : entityFilteredClosedCases;

            const isAllFilteredClosedCases = !isAll
                ? riskFilteredClosedCases.filter(
                    ({ assignedTo }) => assignedTo.some(arrVal => arrVal?.id === Number(localStorage.userID))
                )
                : riskFilteredClosedCases

            const typeFilteredPendingCases = typeFilter
                ? pendingCases.filter(
                      ({ caseType }) => caseType === typeFilter
                  )
                : pendingCases;

            const userFilteredPendingCases = userFilter
                ? typeFilteredPendingCases.filter(
                        ({ assignedTo }) => assignedTo.some(arrVal => arrVal?.id === userFilter)
                    )
                : typeFilteredPendingCases;

            const entityFilteredPendingCases = entityFilter
                ? userFilteredPendingCases.filter(
                        ({ entityTags }) => entityTags.some(arrVal => arrVal === entityFilter)
                    )
                : userFilteredPendingCases;

            const riskFilteredPendingCases = riskFilter
                ? entityFilteredPendingCases.filter(
                        ({ riskLabel }) => {
                            if (riskFilter === 'neutral'){
                                return riskLabel === '' || !riskLabel
                            } else {
                                return riskLabel === riskFilter
                            }
                        }
                    )
                : entityFilteredPendingCases;

            const isAllFilteredPendingCases = !isAll
                ? riskFilteredPendingCases.filter(
                    ({ assignedTo }) => assignedTo.some(arrVal => arrVal?.id === Number(localStorage.userID))
                )
                : riskFilteredPendingCases

            const typeFilteredOverdueCases = typeFilter
                ? overdueCases.filter(
                        ({ caseType }) => caseType === typeFilter
                    )
                : overdueCases;

            const userFilteredOverdueCases = userFilter
                ? typeFilteredOverdueCases.filter(
                        ({ assignedTo }) => assignedTo.some(arrVal => arrVal?.id === userFilter)
                    )
                : typeFilteredOverdueCases;

            const entityFilteredOverdueCases = entityFilter
                ? userFilteredOverdueCases.filter(
                        ({ entityTags }) => entityTags.some(arrVal => arrVal === entityFilter)
                    )
                : userFilteredOverdueCases;

            const riskFilteredOverdueCases = riskFilter
                ? entityFilteredOverdueCases.filter(
                        ({ riskLabel }) => {
                            if (riskFilter === 'neutral'){
                                return riskLabel === '' || !riskLabel
                            } else {
                                return riskLabel === riskFilter
                            }
                        }
                    )
                : entityFilteredOverdueCases;

            const isAllFilteredOverdueCases = !isAll
                ? riskFilteredOverdueCases.filter(
                    ({ assignedTo }) => assignedTo.some(arrVal => arrVal?.id === Number(localStorage.userID))
                )
                : riskFilteredOverdueCases

            setCases(normalizedData);
            setOverdueCases(isAllFilteredOverdueCases);
            setPendingCases(isAllFilteredPendingCases)
            setClosedCases(isAllFilteredClosedCases);
        }
    }, [data, dueDateFilter, entityFilter, isAll, querySchemas, riskFilter, schema, setCases, setClosedCases, setOverdueCases, setPendingCases, startDateFilter, tenants, typeFilter, userFilter]);

    const riskSelected = riskItems.findIndex(e => e.value === riskFilter);

    const entityArray = entityItems.reduce((accumulator, entity) => {
        accumulator.push(entity.value);
        return accumulator;
    }, []);

    const userLabelArray = userItems.reduce((accumulator, user) => {
        accumulator.push(user.label);
        return accumulator;
    }, []);

    const userValueArray = userItems.reduce((accumulator, user) => {
        accumulator.push(user.value);
        return accumulator;
    }, []);

    const typeArray = typeItems.reduce((accumulator, type) => {
        accumulator.push(type.value);
        return accumulator;
    }, []);

    useEffect(() => {
        if (isNaN(userFilter)){
            setUserFilter(userValueArray[userLabelArray.indexOf(userFilter)]);
        }
        // eslint-disable-next-line
    },[]);

		function onDownloadItem(item){
			const caseItem = caseManagementData.find(({id}) => id === item.id)
			exportDetailedCases({
				data: [caseItem],
				isAll,
				name:'Case',
				querySchemas,
				schema,
				tenants,
				userId
			})
		}
    
    useEffect(() => {
        setFilteredCasesData({
            closed: closedCases,
            overdue: overdueCases,
            pending: pendingCases
        })
    }, [closedCases, overdueCases, pendingCases, setFilteredCasesData])

    if (error) {
        return `Error! ${error}`;
    }

    return (
        <ContentPanel className={`${className} ${styles.caseManagementPanel}`}>
            <PanelBody className={styles.panelBody} isLoading={loading}>

                <div className={styles.searchRowContainer}>
                    <SearchInput
                        className={styles.search}
                        onChange={setSearchTerm}
                        placeholder={SEARCH}
                    />

                    <AllMeToggle
                        className={styles.allMeToggle}
                        isActive={isAll}
                        onToggle={handleToggle}
                    />
                </div>


                {typeItems.length > 1 &&
                <Dropdown
                    className={styles.dropdown}
                    items={typeItems}
                    label={CASE_TYPE}
                    onChange={setTypeFilter}
                    selected={typeArray.indexOf(typeFilter)}
                />
                }

                <Dropdown
                    className={styles.dropdown}
                    items={userItems}
                    label={ASSIGNED_TO}
                    onChange={setUserFilter}
                    selected={userValueArray.indexOf(userFilter)}
                />

                <DatePicker
                    className={styles.startDate}
                    label={START_DATE}
                    onChange={setStartDateFilter}
                    value={startDateFilter ? new Date(startDateFilter).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
                />

                <DatePicker
                    className={styles.endDate}
                    label={DUE_DATE}
                    onChange={setDueDateFilter}
                    value={dueDateFilter ? new Date(dueDateFilter).toISOString().split('T')[0]: new Date().toISOString().split('T')[0]}
                />

                <Dropdown
                    className={styles.dropdown}
                    items={entityItems}
                    label={ENTITIES}
                    onChange={setEntityFilter}
                    selected={entityArray.indexOf(entityFilter)}
                />

                <Dropdown
                    className={styles.dropdown}
                    items={riskItems}
                    label={RISK_LABEL}
                    onChange={setRiskFilter}
                    selected={riskSelected}
                />

                <CaseTable 
                    className={styles.table}
                    closedCases={filterDataByName(closedCases, searchTerm)}
                    onDownloadItem={onDownloadItem}
                    overdueCases={filterDataByName(overdueCases, searchTerm)}
                    pendingCases={filterDataByName(pendingCases, searchTerm)}
                />
            </PanelBody>
        </ContentPanel>
    );
};

export default CaseManagementPanel;
