import { memo, useCallback, useEffect, useRef, useState } from 'react';

import RadioButtonWithLabel from 'components/RadioButtonWithLabel';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const DynamicRadioButtons = ({ className = '', globalIsDisabled, items = [], onChange }) => {    
    const previouslySelectedIndex = useRef(items.map(({ isChecked }) => Boolean(isChecked)).flatMap((bool, index) => bool ? index : [])[0]);
    const [selectedRadioButton, setSelectedRadioButton] = useState(items.map(({ isChecked }) => Boolean(isChecked)).flatMap((bool, index) => bool ? index : [])[0]);

    const [radioStates, setRadioStates] = useState(
        items.map(({ isChecked }) => Boolean(isChecked))
    );

    useEffect(() => {
        const previouslySelectedRadioButton = previouslySelectedIndex.current;

        if (previouslySelectedRadioButton === selectedRadioButton) {
            return;
        }

        const updatedState = [...radioStates];

        setRadioStates(previousState => {
            if (previouslySelectedRadioButton === selectedRadioButton) {
                return previousState;
            }

            if (previouslySelectedRadioButton > -1) {
                updatedState[previouslySelectedRadioButton] =
                    !previousState[previouslySelectedRadioButton];
            }

            updatedState[selectedRadioButton] =
                !previousState[selectedRadioButton];

            previouslySelectedIndex.current = selectedRadioButton;

            return updatedState;
        });
    }, [radioStates, selectedRadioButton]);

    const handleChange = useCallback(
        radioButtonIndex => () => {
            setSelectedRadioButton(radioButtonIndex);

            if (onChange && isFunction(onChange)) {
                onChange(items[radioButtonIndex]?.value, radioStates);
            }
        },

        [items, onChange, radioStates]
    );

    return (
        <div className={`${className} ${styles.dynamicRadioButtons}`}>
            {items.map(
                (
                    {
                        className: radioButtonWithLabelClass = '',
                        isDisabled,
                        name,
                        label,
                        value
                    },
                    index
                ) => (
                    <RadioButtonWithLabel
                        className={radioButtonWithLabelClass}
                        isChecked={radioStates[index]}
                        isDisabled={globalIsDisabled ? globalIsDisabled : isDisabled}
                        key={label + index}
                        name={`${name}.${index}`}
                        onChange={globalIsDisabled ? () => {} : handleChange(index)}
                        text={label}
                        value={value}
                    />
                )
            )}
        </div>
    );
};

export default memo(DynamicRadioButtons);
