import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { createCaseModal } = state;

const { createCaseModalAtom } = createCaseModal.atoms;

const MUTATE_CREATE_CASE = tenantSchema => gql`
mutation createCase(
    $caseName: String,
    $caseType: Int!,
    $formId: Int,
    $createdBy: Int!,
    $creationReason: String!,
    $dueDate: timestamp!,
    $originated: String!, 
    $policyId: Int,
    $sectionId: Int,
    $riskLevel: String) {
    insert_${tenantSchema}_case(objects: {
        case_name: $caseName,
        case_type: $caseType,
        created_by: $createdBy,
        creation_reason: $creationReason,
        due_date: $dueDate,
        originated: $originated,
        policy_id: $policyId,
        section_id: $sectionId,
        form_id: $formId,
        risk_label: $riskLevel}) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_CASE_USER = tenantSchema => gql`
mutation createCaseUser(
    $assignedTo: Int!,
    $caseId: Int!) {
    insert_${tenantSchema}_user_case(objects: {
        case_id: $caseId,
        user_id: $assignedTo}) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_INSERT_NEW_POLICY_REFERENCES = tenantSchema => gql`
    mutation insertCasePolicy(
        $caseId: Int!,
        $policyId: Int!,
        $sectionId: Int
    ) {
        insert_${tenantSchema}_case_policies(objects: {
            case_id: $caseId, 
            document_mapping_id: $policyId,
            document_section_id: $sectionId
        }) {
            returning {
                document {
                    description
                    id
                    name
                    risk_label
                }
                section {
                    description
                    id
                    name
                    riskLevel
                }
            }
        }
    }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_CREATE_CASE, providedSchema);
    const mutationUser = useGraphQL(MUTATE_CASE_USER, providedSchema);
    const mutationInsertPolicies = useGraphQL(MUTATE_INSERT_NEW_POLICY_REFERENCES, providedSchema);

    const { useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userSetSchema] = useUserSetSchema();

    const usedSchema = providedSchema ? providedSchema : userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;

    const createCase = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            
            const res = await mutation(variables);

            const assignedUsers = [];

            const id =
                res?.data[`insert_${usedSchema}_case`]?.returning[0]
                    ?.id;

            try {
                const assignedTo = JSON.parse(variables.assignedTo);
                if (assignedTo.constructor === Array) {
                    for (var i = 0; i < assignedTo.length; i++) {
                        assignedUsers.push({
                            assignedTo: assignedTo[i].value,
                            caseId: id
                        });
                    }
                }
            } catch {
                if (variables.assignedTo.constructor !== Array) {
                    assignedUsers.push({
                        assignedTo: variables.assignedTo,
                        caseId: id
                    });
                }
            }

            for (var j = 0; j < assignedUsers.length; j++) {
                if(assignedUsers[j]?.assignedTo){
                    await mutationUser(assignedUsers[j]);
                }
            }

            try {
                if(variables.policyId){
                    await mutationInsertPolicies({
                        caseId: id,
                        policyId: variables.policyId,
                        sectionId: null,
                    });
                }
            } catch (e) {
                console.log(e);
            }

            set(createCaseModalAtom, id);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(createCaseModalAtom, '');
    });

    return [useRecoilValue(createCaseModalAtom), createCase, reset];
};

export default useData;
