import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { deleteCaseModal } = state;

const { deleteCaseModalAtom } = deleteCaseModal.atoms;

const MUTATE_DELETE_CASE = tenantSchema => gql`
mutation deleteCase($caseId: Int!, $deletedDate: timestamp!) {
    update_${tenantSchema}_case(where: {
        id: {
            _eq: $caseId}
        }, 
        _set: {
            deleted: true,
            deleted_date: $deletedDate
        }) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_CASE_HISTORY = tenantSchema => gql`
    mutation insert_case_history_deleted($caseId: Int!, $color: String = "red", $message: String = "deleted the case", $userId: Int!) {
        insert_${tenantSchema}_case_history(
            objects: {
                case_id: $caseId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_DELETE_CASE, providedSchema);
    const mutationCaseHistory = useGraphQL(MUTATE_CASE_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema || schema;

    const removeCase = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            variables['deletedDate'] = new Date();
            const res = await mutation(variables);
            console.log("res", res)
            await mutationCaseHistory(variables);

            const id = res?.data[`update_${usedSchema}_case`].returning[0]?.id;

            set(deleteCaseModalAtom, id);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(deleteCaseModalAtom, '');
    });

    return [useRecoilValue(deleteCaseModalAtom), removeCase, reset];
};

export default useData;
