import styles from './styles.module.scss';

const Spinner = ({ type = 'dot', className = '' }) => {
    return (
        <div className={`${styles.spinner} ${className}`}>
            {type === 'save' ? (
                <div className={styles.saveSpinner}></div>
            ) : (
                <div className={styles.dot}></div>
            )}
        </div>
    );
};

export default Spinner;
