import { useRef, useState } from 'react';

import Comment from 'components/Comment';
import CommentInput from 'components/CommentInput';
import ErrorMessage from 'components/ErrorMessage';
import SectionWithBorder from 'components/SectionWithBorder';
import Title from 'components/Title';

import useForm from 'hooks/useForm';

import useConstants from './hooks/useTranslation';

import styles from './styles.module.scss';
import isFunction from 'utilities/isFunction';
import { useGetCommentUserTagging } from 'queries/users/users.query';
import { usePermissions } from 'hooks/usePermissions';
import { commentUserTaggingPermissions } from 'constants';

const Comments = props => {
    const {
        className = '',
        closed,
        commentType,
        commentTypeId,
        commentTypeName,
        comments,
        hasEdit = true,
        isCreatingPDF,
        onDelete,
        onSubmit,
        onUpdate,
        usedSchema,
        user
    } = props;

    const { COMMENTS } = useConstants();

    const commentRef = useRef();

    const [errorMessage, setErrorMessage] = useState();

    const { handleError, handleForm, handleValid } = useForm({
        comment: ''
    });

    const { hasPermissions } = usePermissions();

    const { data: commentUsersAndGroups = [] } = useGetCommentUserTagging({
        excludeCertificationRole: !hasPermissions([
            commentUserTaggingPermissions
        ]),
        usedSchema,
    });

    const handleCommentsSubmit = () => {
        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }
        if (isFunction(onSubmit)) {
            onSubmit(output);
        }
    };

    return (
        <SectionWithBorder className={`${styles.comments} ${className}`}>
            <Title text={COMMENTS} />

            <CommentInput
                commentType={commentType}
                commentTypeId={commentTypeId}
                commentTypeName={commentTypeName}
                commentUsersAndGroups={commentUsersAndGroups}
                onError={handleError('comment')}
                onSubmit={handleCommentsSubmit}
                onValid={handleValid('comment')}
                ref={commentRef}
                usedSchema={usedSchema}
                user={user}
            />

            <ErrorMessage className={styles.errorMessage} text={errorMessage} />

            <div
                className={isCreatingPDF ? '' : styles.verticalScrollContainer}
            >
                {/* <div className={styles.verticalScroll}> */}

                {comments.map(
                    ({ content, createdAt, deleted, id, user }, index) => (
                        <Comment
                            closed={closed}
                            commentId={id}
                            content={content}
                            createdAt={createdAt}
                            deleted={deleted}
                            hasEdit={hasEdit}
                            key={`${id}${index}`}
                            onDelete={onDelete}
                            onUpdate={onUpdate}
                            user={user}
                        />
                    )
                )}

                {/* </div> */}
            </div>
        </SectionWithBorder>
    );
};

export default Comments;
