import { useMemo } from 'react';

import PeopleTableHeader from 'components/PeopleTableHeader';
import PeopleTableRow from 'components/PeopleTableRow';
import Table from 'components/Table';

import styles from './styles.module.scss';

const PeopleTable = ({
    className = '',
    columns = [],
    isAllRowsSelected,
    selectedRows = [],
    toggleAllRows,
    toggleRowSelection,
    users = []
}) => {
    const selectedColumns = useMemo(
        () =>
            columns
                .filter(column => column.isSelected)
                .map(column => column.key),
        [columns]
    );
    
    return (
        <Table className={className}>
            <PeopleTableHeader
                columns={columns}
                isAllRowsSelected={isAllRowsSelected}
                toggleAllRows={toggleAllRows}
            />

            <div className={styles.tableBody}>
                {users.map(
                    ({
                        id,
                        firstName,
                        email,
                        groups,
                        lastName,
                        registration,
                        role,
                        status,
                        tenant,
                        username
                    }) => (
                        <PeopleTableRow
                            // avatar={avatar}
                            email={email}
                            firstName={firstName}
                            groups={groups}
                            id={id}
                            isSelected={selectedRows.includes(id)}
                            key={id}
                            lastName={lastName}
                            registration={registration}
                            role={role}
                            selectedColumns={selectedColumns}
                            status={status}
                            tenant={tenant}
                            toggleSelection={toggleRowSelection}
                            username={username}
                        />
                    )
                )}
            </div>

        </Table>
    );
};

export default PeopleTable;
