import CaseDetailsPanel from 'components/CaseDetailsPanel';
import CaseMetaPanel from 'components/CaseMetaPanel';
import PanelWithAsidePanel from 'components/PanelWithAsidePanel';

import styles from './styles.module.scss';

const CaseDetailsTab = ({
    attachments,
    attachmentsOnDelete,
    attachmentsOnUpdate,
    caseSchema,
    formsRef,
    handleFileUpload,
    isCreatingPDF,
    items,
    onRefetchCase
}) => (
    <div id="case-view">
        <PanelWithAsidePanel>
            <CaseDetailsPanel
                attachments={attachments}
                attachmentsOnDelete={attachmentsOnDelete}
                attachmentsOnUpdate={attachmentsOnUpdate}
                caseSchema={caseSchema}
                cases={items}
                formsRef={formsRef}
                handleFileUpload={handleFileUpload}
                isCreatingPDF={isCreatingPDF}
                onRefetchCase={onRefetchCase}
            />

            <CaseMetaPanel
                caseSchema={caseSchema}
                cases={items}
                className={styles.caseMetaPanel}
            />
        </PanelWithAsidePanel>
    </div>
);

export default CaseDetailsTab;
