import PanelBody from 'components/PanelBody';
import { ActivityTableItems } from './activities-table-items.component';
import styles from './styles.module.scss';

export const ActivitiesTable = ({
    activities = [],
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isMultiTenant,
    onCompleteActivity,
    onToggleFlag,
    title
}) => {
    const TableItems = ({ activities, title }) => (
        <ActivityTableItems
            activities={activities}
            isMultiTenant={isMultiTenant}
            onCompleteItem={onCompleteActivity}
            onToggleFlagItem={onToggleFlag}
            title={title}
        />
    );

    return (
        <PanelBody>
            {activities.length > 0 && (
                <TableItems activities={activities} title={title} />
            )}

            {hasNextPage && (
                <p
                    className={styles.loadMore}
                    onClick={() => !isFetching && fetchNextPage()}
                >
                    {isFetchingNextPage
                        ? 'Loading...'
                        : hasNextPage
                        ? 'Load More'
                        : ''}
                </p>
            )}
        </PanelBody>
    );
};
