import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';

import isFunction from 'utilities/isFunction';

import useActivityId from './hooks/useActivityId';
import useData from './hooks/useData';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import CloseAndCreateCaseMenuListItem from 'components/CloseAndCreateCaseMenuListItem';

const CloseActivityAndCreateCaseMenuListItem = ({
    activityId,
    activityRecord,
    activitySchema,
    onBeforeClose,
    onClick,
    onSave,
    riskLabel
}) => {
    const [_, setActivityLinkId] = useActivityId();
    const [closedActivity, closeActivity, reset] = useData(activitySchema);
    const { useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();

    const { HOME } = useConfig();

    const navigate = useNavigate();

    const handleActivityClose = useCallback(async() => {
        if(onBeforeClose) await onBeforeClose();
        setActivityLinkId({
            activityId: activityId,
            activityName: activityRecord?.name
        });
        await closeActivity({
            activityId,
            userId
        });

        if (isFunction(onSave)) {
            onSave();
        }
    }, [activityId, activityRecord, closeActivity, onBeforeClose, onSave, setActivityLinkId, userId]);

    useEffect(() => {
        if (!closedActivity) {
            return;
        }

        if (isFunction(onClick)) {
            onClick(closedActivity);
        }

        setTimeout(() => {
            navigate(HOME);
        }, 1000);

        reset();
    }, [HOME, closedActivity, navigate, onClick, reset]);
    return (
        <CloseAndCreateCaseMenuListItem
            activityRecord={activityRecord}
            linkSchema={activitySchema}
            onSave={handleActivityClose}
            riskLabel={riskLabel}
        />
    );
};

export default CloseActivityAndCreateCaseMenuListItem;
